import { Component, DestroyRef, HostListener, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { IAppEvent } from '@app/shared/types/app-event-interface';
import { AppStateService } from '@app/shared/services/app-state.service';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ButtonModule } from 'primeng/button';
import { SCORE_RANGE, ScoringService } from '@app/shared/services/scoring.service';
import { environment } from '@env/environment';

@Component({
  selector: 'app-score-panel',
  standalone: true,
  imports: [CommonModule, ButtonModule],
  templateUrl: './score-panel.component.html',
  styleUrls: ['./score-panel.component.scss'],
  animations: [],
})
export class ScorePanelComponent implements OnInit {
  protected appEvent$: Observable<IAppEvent>;
  protected showPage = false;
  protected range = 0;
  protected rangeEnum = SCORE_RANGE;
  protected scoringService = inject(ScoringService);

  private readonly _appStateService = inject(AppStateService);
  private readonly _destroyRef = inject(DestroyRef);

  constructor() {}

  ngOnInit() {
    this.appEvent$ = this._appStateService.appEvent$;
    this.appEvent$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event.area === APP_EVENT_AREAS.SHOW_SCORE) {
        this.range = this.scoringService.getScoreRange();
      }
    });
  }

  toggleVisibility() {
    this.showPage = !this.showPage;
  }

  @HostListener('document:keydown', ['$event'])
  clickKeyDown(event: KeyboardEvent) {
    if (event.ctrlKey && event.shiftKey && event.key === 'X') {
      if (!environment.production) {
        this.showPage = !this.showPage;
      }
    }
  }
}
