import { DestroyRef, inject, Injectable } from '@angular/core';
import { SOUND_NAMES } from '@app/shared/constants/sound-enums';
import { AppStateService } from '@app/shared/services/app-state.service';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { BehaviorSubject } from 'rxjs';
import { Howl } from 'howler';
import { AppHelpers } from '@app/shared/helpers/app-helpers';
import { environment } from '@env/environment';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IActivityRegistry } from '../types/activity-registry.interface';
import { DELAY } from '../constants/delay-enums';

@Injectable({
  providedIn: 'root',
})
export class SoundService {
  private readonly _soundEffects = new Map<string, string>();
  private _activity: IActivityRegistry;
  private readonly _appStateService = inject(AppStateService);
  private _sound: Howl;
  private _soundEffect: Howl;
  private readonly _soundEffectVolume = 0.15;
  private _level: number;
  public soundIsPlaying$ = new BehaviorSubject<boolean>(false);

  constructor(private readonly _destroyRef: DestroyRef) {
    const soundEffectsUrl = `${environment.CDN_URL}/activities/app-sound-effects/`;

    this._soundEffects.set(SOUND_NAMES.Correct, `${soundEffectsUrl}mixkit-correct-answer-tone-2870.wav`);
    this._soundEffects.set(SOUND_NAMES.Incorrect, `${soundEffectsUrl}mixkit-click-error-1110.wav`);
    this._soundEffects.set(SOUND_NAMES.NextCard, `${soundEffectsUrl}mixkit-happy-bell-alert-601.wav`);
    this._soundEffects.set(SOUND_NAMES.FlipCard, `${soundEffectsUrl}mixkit-air-in-a-hit-2161.wav`);
    this._soundEffects.set(SOUND_NAMES.Click, `${soundEffectsUrl}mixkit-modern-technology-select-3124.wav`);
    this._soundEffects.set(SOUND_NAMES.Achievement, `${soundEffectsUrl}mixkit-achievement-bell-600.wav`);
    this._soundEffects.set(SOUND_NAMES.Highlight, `${soundEffectsUrl}mixkit-unlock-game-notification-253.wav`);
    this._soundEffects.set(SOUND_NAMES.ProgressComplete, `${soundEffectsUrl}mixkit-completion-of-a-level-2063.wav`);
    this._soundEffects.set(SOUND_NAMES.ActivityComplete, `${soundEffectsUrl}mixkit-melodic-bonus-collect-1938.wav`);
    this._soundEffects.set(SOUND_NAMES.FlipCoin, `${soundEffectsUrl}mixkit-arcade-game-jump-coin-216.wav`);
    this._soundEffects.set(SOUND_NAMES.Confetti, `${soundEffectsUrl}mixkit-video-game-treasure-2066.wav`);
    this._soundEffects.set(SOUND_NAMES.Dance, `${soundEffectsUrl}mixkit-dance-with-me-3.mp3`);
    this._soundEffects.set(SOUND_NAMES.Bubbles, `${soundEffectsUrl}mixkit-ocean-game-movement-water-air-tank-bubbles-huge-long-3017.wav`);
    this._soundEffects.set(SOUND_NAMES.FinalScore, `${soundEffectsUrl}mixkit-bonus-extra-in-a-video-game-2064.wav`);
    this._soundEffects.set(SOUND_NAMES.BubbleBurst, `${soundEffectsUrl}liquid bubble.wav`);
    this._soundEffects.set(SOUND_NAMES.FairyArcade, `${soundEffectsUrl}fairy arcade sparkle.wav`);
    this._soundEffects.set(SOUND_NAMES.FairyMagicSparkle, `${soundEffectsUrl}fairy magic sparkle.wav`);
    this._soundEffects.set(SOUND_NAMES.LightSpell, `${soundEffectsUrl}light spell.wav`);
    this._soundEffects.set(SOUND_NAMES.GameLevelCompleted, `${soundEffectsUrl}game level completed.wav`);

    this._appStateService.currentActivity$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((activity) => {
      this._activity = activity;
      this._level = +this._appStateService.level;
    });
  }

  playSound(soundName: string, callback?: () => void | undefined, soundVolume?: number) {
    const soundFile = this._soundEffects.get(soundName);
    const useHtml5 = !AppHelpers.isSafari();

    if (!soundVolume) soundVolume = this._soundEffectVolume;

    if (soundFile) {
      this._soundEffect = new Howl({
        src: [soundFile],
        volume: soundVolume,
        loop: false,
        html5: useHtml5,
        onend: () => {
          if (callback) callback();
        },
        onloaderror: () => {
          if (callback) callback();
        },
      });
      this._soundEffect.load();
      this._soundEffect.play();
    }

  }



  playExpression(exp: string | undefined, callback?: () => void | undefined, isAppExpression = false, isMarkingExpression = false) {
    if (!exp?.length) {
      if (callback) callback();
      return;
    }

    if (this.soundIsPlaying$.value) {
      this.stopExpression();
    }

    this.hashText(exp || '').then((hash) => {
      const file = `${hash}.mp3`;

      // hash audio we look for
      let audioUrl = !isAppExpression
        ? `${environment.CDN_URL}/activities/${this._activity.id}/level-${this._level}/${hash}.mp3`
        : `${environment.CDN_URL}/activities/app-expressions/${hash}.mp3`;
      if (isMarkingExpression) {
        audioUrl = `${environment.CDN_URL}/activities/app-markings/${hash}.mp3`;
      }

      // versioning to fix CDN cache error issue and force download
      audioUrl += `?v=${AppHelpers.generateId()}`;

      this._appStateService.appEvent$.next({
        area: APP_EVENT_AREAS.Sound, disableUserInteraction: true, message: exp
      });
      this.playFile(
        audioUrl,
        1,
        false,
        () => {
          this._appStateService.appEvent$.next({ area: APP_EVENT_AREAS.Sound, disableUserInteraction: false });
          if (callback) callback();
        },
        () => {
          this._appStateService.errorDialog(file, exp, callback);
        }
      );
    });
  }

  playExpressions(expressions: string[], onExpCompleteCallback: (idx: number, count?: number) => void, onAllExpCompleteCallback: () => void) {
    let index = 0;
    let count = 0;

    const processNext = () => {
      index++;
      if (index < expressions.length) {
        this.playExpression(expressions[index], () => {
          if (expressions[index].includes('/')) {
            onExpCompleteCallback(index, count);
            count++;
          } else {
            count = 0;
          }
          setTimeout(() => {
            processNext();
          }, expressions[index].includes('/') ? DELAY.S1 : DELAY.ZERO)
        })
      } else {
        onAllExpCompleteCallback();
      }
    }

    this.playExpression(expressions[index], () => {
      if (expressions[index].includes('/')) {
        onExpCompleteCallback(index);
      }
      processNext();
    })
  }

  pauseExpression() {
    if (this._sound.playing()) {
      this._sound.pause();
      this.soundIsPlaying$.next(false);
    } else {
      this._sound.play();
      this.soundIsPlaying$.next(true);
    }
  }


  stopExpression() {
    this._sound.stop();
    this.soundIsPlaying$.next(false);
  }

  private async hashText(text: string) {
    const key = `${text.trimStart().trimEnd().toLowerCase()}-${this._level}`;

    const msgUint8 = new TextEncoder().encode(key); // encode as (utf-8) Uint8Array
    const hashBuffer = await crypto.subtle.digest('SHA-256', msgUint8); // hash the message
    const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
    const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join(''); // convert bytes to hex string
    return hashHex.toUpperCase();
  }

  playFile(
    soundFile: string,
    volume: number,
    loop: boolean,
    callback?: () => void | undefined,
    errorCallback?: (err: any) => void | undefined
  ) {

    let processedError = false;

    const useHtml5 = !AppHelpers.isSafari();

    if (this.soundIsPlaying$.value) {
      this.stopExpression();
    }

    if (soundFile) {
      this._sound = new Howl({
        src: [soundFile],
        volume: volume,
        loop: loop,
        onend: () => {
          if (callback) callback();
          this.soundIsPlaying$.next(false);
        },
        onloaderror: (id, err) => {
          if (!processedError) {
            this._appStateService.appEvent$.next({ area: APP_EVENT_AREAS.Sound, disableUserInteraction: false });
            if (errorCallback) errorCallback(err);
            this.soundIsPlaying$.next(false);
          }
          processedError = true;
        },
        html5: useHtml5,
      });
      this._sound.load();
      this._sound.play();
      this.soundIsPlaying$.next(true);
    }
  }
}
