import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, OnDestroy, ViewChild } from '@angular/core';
import { KEY_EVENTS } from '@app/shared/constants/key-event-enums';
import { ICard } from '@app/shared/components/card/types/card-interface';
import { ActivityBaseComponent } from '../activity-base/activity-base.component';
import { TWSentenceComponent } from './puzzles/0/tw-sentence.component';
import { ITrickWordsDataset } from './types/tw-dataset-interface';
import { DELAY } from '@app/shared/constants/delay-enums';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SharedModule } from 'primeng/api';
import { TooltipModule } from 'primeng/tooltip';
import { AnimationHelpers } from '@app/shared/helpers/animation';
import { ANIMATIONS } from '@app/shared/constants/animation-enums';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { A11yModule } from '@angular/cdk/a11y';
import { StartButtonComponent } from '@app/shared/components/start-button/start-button.component';
import { AppStateService } from '@app/shared/services/app-state.service';
import { CardRotatorComponent } from '@app/shared/components/carousel/card-rotator.component';
import { BURST_TYPE } from '@app/shared/types/animation-burst.interface';
import { ProgressBarComponent } from '@app/shared/components/progress-bar/progress-bar.component';
import { AnimationBurst } from '@app/shared/services/animation-burst.service';
import { SOUND_NAMES } from '@app/shared/constants/sound-enums';


enum PUZZLE_TYPE {
  TrickWords = 0,
  SoundAlike = 1,
}

@Component({
  selector: 'app-not-so-tricky',
  templateUrl: './not-so-tricky-activity.component.html',
  styleUrls: ['./not-so-tricky-activity.component.scss'],
  standalone: true,
  providers: [],
  imports: [
    CommonModule,
    TWSentenceComponent,
    SharedModule,
    TooltipModule,
    A11yModule,
    StartButtonComponent,
    CardRotatorComponent,
    ProgressBarComponent
  ],
})
export class NotSoTrickyActivityComponent extends ActivityBaseComponent implements OnDestroy {
  @ViewChild('carousel') carousel!: CardRotatorComponent;
  @ViewChild(ProgressBarComponent)
  private readonly _progressBarComp!: ProgressBarComponent;

  wordCards: ICard[] = [];
  protected TWDatasets: ITrickWordsDataset[];
  protected carouselCompleted = false;
  protected carouselProceed = false;
  protected currentTrickWordDataset: ITrickWordsDataset | undefined;
  protected currentPuzzleType: PUZZLE_TYPE = PUZZLE_TYPE.TrickWords;
  protected showingTransitionPage = false;
  protected totalPuzzles: number = 0;
  protected isRecordingEnabled: boolean = false;
  protected answeredPuzzles: number = 0;

  private readonly _destroyRef = inject(DestroyRef);
  protected burst = inject(AnimationBurst);

  expressionMap = {
    success: 1,
    puzzleInstructions: 2,
    failureFinal: 3,
    carouselInstructions: 4,
    generalPuzzleInstructions: 5,
  };

  constructor(override appStateService: AppStateService) {
    super();

    // Subscribe to the recording state from AppStateService
    this.appStateService.isRecordingEnabled$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((isRecordingEnabled: boolean) => {
        this.isRecordingEnabled = isRecordingEnabled;

        this.expressionMap.carouselInstructions = isRecordingEnabled ? 4.1 : 4;
      });
  }

  initActivity(callback?: () => void | undefined): void {
    this.loadDataset()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((datasetSrc) => {
        this.TWDatasets = datasetSrc as ITrickWordsDataset[];
        this.totalPuzzles = this.TWDatasets.length;
        const nextPuzzle = this.TWDatasets.find((p) => !p.completed);
        this.currentPuzzleType = nextPuzzle ? nextPuzzle.puzzleType : PUZZLE_TYPE.SoundAlike;

        this.setPerfectScore(this.TWDatasets?.length ?? 0);
        this.markerCount = this.TWDatasets?.filter((d) => d.puzzleType === this.currentPuzzleType).length;

        this.answeredPuzzles = this.TWDatasets.filter((d) => d.completed)?.length;

        this.TWDatasets?.filter((d) => !d.completed).sort((a, b) => {
          if (a.puzzleType === PUZZLE_TYPE.SoundAlike && b.puzzleType === PUZZLE_TYPE.SoundAlike) {
            return 0.5 - Math.random();
          } else {
            return 1;
          }
        });


        this.listenForJumpToPuzzle();

        if (callback) callback();

      });

    this.listenForCompleteAllPuzzles();
  }

  onCarouselCompleted() {
    this.carouselCompleted = true;
    AnimationHelpers.animate('play-button', ANIMATIONS.Pulse, DELAY.M30);
  }

  onPlayWord(word: string) {
    let choice: string | undefined;
    if (this.TWDatasets) {
      for (const dataset of this.TWDatasets) {
        const choices = dataset.choices.split(',');
        choice = choices.find((c) => c.toLocaleLowerCase().trimAll() === word.toLocaleLowerCase().trimAll());
        if (choice) {
          break;
        }
      }
    }

    if (choice) {
      this.soundService.playExpression(choice);
    }
  }

  onCarouselProceedClick() {
    this.carouselProceed = true;

    this.appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.PROFILE_NARRATOR,
    });
    this._progressBarComp.markedComplete(this.TWDatasets.filter((d) => d.completed)?.length);

    this.currentTrickWordDataset = this.TWDatasets?.find((tw) => !tw.completed);


    this.soundService.playExpression(this.getExpression(this.expressionMap.generalPuzzleInstructions), () => {
      this.buildExpressions(this.currentTrickWordDataset);
    });
    this.appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.PUZZLE_CLUE,
      puzzleClueExpression: this.getExpression(this.expressionMap.puzzleInstructions) ?? '',
    });
  }

  startActivity() {
    if (this.activitySolved) {
      this.resetActivity();
    }

    this.initActivity(() => {
      this.soundService.playExpression(this.currentActivity?.startExpression, () => {
        this.setCarouselCards();
        const startPrompt = this.TWDatasets[0];
        this.buildExpressions(startPrompt)
        this.currentTrickWordDataset = this.TWDatasets?.find((tw) => !tw.completed);
        this.soundService.playExpression(this.getExpression(this.expressionMap.carouselInstructions));
        this.appStateService.appEvent$.next({
          area: APP_EVENT_AREAS.PUZZLE_CLUE,
          puzzleClueExpression: this.getExpression(this.expressionMap.carouselInstructions) ?? '',
        });
      });
    });
  }

  processKeyEvent(event: KeyboardEvent) {
    switch (event.key) {
      case KEY_EVENTS.SpaceBar:
        break;
      case KEY_EVENTS.Enter:
        break;
    }
  }

  onCompletedPuzzle(data) {
    this._progressBarComp.proceed(() => {
      if (this.currentTrickWordDataset) {
        this.currentTrickWordDataset.completed = true;

        this.currentTrickWordDataset = this.TWDatasets?.filter((tw) => !tw.completed)[0];
        this.buildExpressions(this.currentTrickWordDataset);

        this.appStateService.appEvent$.next({
          area: APP_EVENT_AREAS.PUZZLE_CLUE,
          puzzleClueExpression: this.getExpression(this.expressionMap.puzzleInstructions) ?? '',
        });
      }
      if (!this.currentTrickWordDataset) {
        this.showActivitySolved();
      } else {
        this.updateSession();
        if (this.currentPuzzleType !== this.currentTrickWordDataset.puzzleType) {


          this.showTransitionPage(() => {
            this.soundService.playExpression(this.getExpression(6), () => { });
            this.currentPuzzleType = this.currentTrickWordDataset?.puzzleType ?? 0;
            setTimeout(() => {
              this.showingTransitionPage = false;
              this.carouselProceed = false;
              this.carouselCompleted = false;
              this.setCarouselCards();

              this.soundService.playExpression(this.getExpression(this.expressionMap.carouselInstructions));
              this.appStateService.appEvent$.next({
                area: APP_EVENT_AREAS.PUZZLE_CLUE,
                puzzleClueExpression: this.getExpression(this.expressionMap.carouselInstructions) ?? '',
              });
              this._progressBarComp.markedComplete(this.TWDatasets.filter((d) => d.completed)?.length);
            }, DELAY.S1);

          });
        }
      }
    }, data.passed);
  }

  showTransitionPage(callback?: () => void | undefined) {
    this.showingTransitionPage = true;
    this.appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.CENTER_NARRATOR,
    });
    setTimeout(() => {
      this.burst.animate({ containerId: "narrator-host", offsetId: 'narrator-burst-1', toTop: false, doublePos: 1 }, BURST_TYPE.Round, true);
      this.burst.animate({ containerId: "narrator-host", offsetId: 'narrator-burst-2', toTop: false, doublePos: 2, soundEffect: SOUND_NAMES.Achievement }, BURST_TYPE.Round, true);
    }, DELAY.S2);



    setTimeout(() => {
      this.soundService.playExpression(this.getExpression(this.expressionMap.generalPuzzleInstructions), () => {

        this.markerCount = this.TWDatasets?.filter((d) => d.puzzleType === this.currentPuzzleType).length;
        this.appStateService.appEvent$.next({
          area: APP_EVENT_AREAS.PROFILE_NARRATOR,
        });
        if (callback) callback();
      });
    }, DELAY.S4);
  }

  showActivitySolved() {
    this._progressBarComp.progressBarComplete(() => {
      this.activitySolved = true;
      this.toggleActivityCompletePage(true);
      this.playEndExpression(() => { });
    });
  }

  private setCarouselCards() {
    const filteredPuzzles = this.TWDatasets.filter((puzzle) => puzzle.puzzleType === this.currentPuzzleType && !puzzle.completed);
    const carouselWords = new Set(filteredPuzzles.map((puzzle) => puzzle.word));

    this.wordCards = [...carouselWords].map((content, index) => ({
      index,
      content,
      active: false,
    }));
  }

  private resetActivity() {
    this.activitySolved = false;
    this.carouselCompleted = false;
    this.carouselProceed = false;
    this.toggleActivityCompletePage(false);
  }

  private listenForJumpToPuzzle() {
    this.appStateService.appEvent$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event.area === APP_EVENT_AREAS.JUMP_TO_PUZZLE) {
        if (event.payload < this.TWDatasets.length) {
          this.carouselCompleted = true;
          this.carouselProceed = true;
          this.currentTrickWordDataset = this.TWDatasets[event.payload];
          this.buildExpressions(this.currentTrickWordDataset);
        }
      }
    });
  }

  private listenForCompleteAllPuzzles(): void {
    this.appStateService.appEvent$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event.area === APP_EVENT_AREAS.COMPLETE_ALL_PUZZLES) {
        this.completeAllPuzzles();

        // Emit event to jump to the first puzzle to bypass the carousel
        this.carouselCompleted = true;
        this.carouselProceed = true;

        // After jumping, check if the activity should be completed
        setTimeout(() => {
          this.updateActivityAfterCompletion();
        }, DELAY.SMALL);
      }
    });
  }


  private updateActivityAfterCompletion(): void {
    this.currentTrickWordDataset = this.TWDatasets?.find((tw) => !tw.completed);
    if (!this.currentTrickWordDataset) {
      this.showActivitySolved();
    } else {
      this.currentPuzzleType = this.currentTrickWordDataset.puzzleType;
    }
  }
}
