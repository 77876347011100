import gsap from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { DestroyRef, Injectable, inject } from '@angular/core';
import { SoundService } from '@app/shared/services/sound.service';
import { Point } from '@app/shared/types/point';
import { AppStateService } from './app-state.service';
import { IBurstThemes, IBurstOptions, BURST_TYPE, IMotionPath, BURST_THEME } from '../types/animation-burst.interface';

@Injectable({
  providedIn: 'root',
})
export class AnimationBurst {
  private readonly _soundService = inject(SoundService);

  private readonly _burstThemes: IBurstThemes[] = [
    {
      id: BURST_THEME.Level1,
      level: 1,
      scale: 1.0,
      svgImages: [
        `/assets/confetti01.svg`,
        `/assets/confetti02.svg`,
        `/assets/confetti03.svg`,
        `/assets/confetti04.svg`,
        `/assets/confetti05.svg`,
        `/assets/confetti06.svg`,
        `/assets/confetti07.svg`,
        `/assets/confetti08.svg`,
        `/assets/confetti09.svg`,
        `/assets/confetti10.svg`,
        `/assets/confetti11.svg`,
        `/assets/confetti12.svg`,
        `/assets/streamer01.svg`,
        `/assets/streamer02.svg`,
        `/assets/streamer03.svg`,
        `/assets/streamer04.svg`,
        `/assets/streamer05.svg`,
        `/assets/streamer06.svg`,
        `/assets/streamer07.svg`,
        `/assets/streamer08.svg`,
      ],
    },
    {
      id: BURST_THEME.Level2,
      level: 2,
      scale: 1.0,
      svgImages: [
        `/assets/bubbles01.svg`,
        `/assets/bubbles02.svg`,
        `/assets/bubbles03.svg`,
        `/assets/starfish01.svg`,
        `/assets/starfish02.svg`,
      ],
    },
    {
      id: BURST_THEME.Level3,
      level: 3,
      scale: 1.0,
      svgImages: [`/assets/snowflake01.svg`, `/assets/snowflake02.svg`, `/assets/snowflake03.svg`],
    },
    {
      id: BURST_THEME.Bubbles,
      level: 0, // custom theme
      scale: 1.0,
      svgImages: [
        `/assets/object-burst-bubble-1-level2.svg`,
        `/assets/object-burst-bubble-2-level2.svg`,
        `/assets/object-burst-bubble-3-level2.svg`,
        `/assets/object-burst-bubble-4-level2.svg`,
        `/assets/object-burst-bubble-5-level2.svg`
      ],
    },
  ];

  private readonly defaultRoundBurstSize = 175;

  private readonly _appStateService = inject(AppStateService);
  protected destroyRef = inject(DestroyRef);

  constructor() {
    gsap.registerPlugin(MotionPathPlugin);
  }

  public animate(options: IBurstOptions, burstType: BURST_TYPE = BURST_TYPE.Default, showBehind: boolean = true, burstTheme?: BURST_THEME) {
    this.doAnimate(options, burstType, showBehind, burstTheme ?? BURST_THEME.Unknown);
  }

  private doAnimate(options: IBurstOptions, burstType: BURST_TYPE, showBehind: boolean, burstTheme: BURST_THEME) {
    // Setup parent container and focus container elements
    const containerId = options.containerId ?? 'activity-container';
    const container = document.getElementById(containerId);
    const focusId = options.offsetId;
    const focusEl = focusId ? document.getElementById(focusId) : null;

    if (!container) {
      if (options.callback) options.callback();
      return;
    }



    const containerRect = container?.getBoundingClientRect();
    const containerWidth = containerRect?.width ?? 0;
    const containerHeight = containerRect?.height ?? 0;
    let containerBottomLeftX = containerRect.left;
    let containerBottomLeftY = containerRect.top + containerHeight;
    const defaultSpread = 0.35;
    const defaultCount = burstType === BURST_TYPE.Round ? 40 : 50;
    const frag = document.createDocumentFragment();

    let xOffset = 0;
    let yOffset = 0;

    let focusPoint;
    if (options.offsetId) {
      focusPoint = options.toTop ? this.getTopCoordinates(focusEl) : this.getCenterCoordinates(focusEl);
    } else {
      focusPoint = this.getCenterCoordinates(container);
    }

    // get the container's css scale if page isn't full-size
    const computedStyle = window.getComputedStyle(container);
    let scale = parseFloat(computedStyle.transform.split(',')[0].slice(7));

    // 70% results in too much movement, 60% tones it down
    scale = scale === 0.7 ? 0.6 : scale;
    scale = scale ? 1 - scale + 1 : 1;

    containerBottomLeftX = containerBottomLeftX * scale;
    containerBottomLeftY = containerBottomLeftY * scale;
    focusPoint.x = focusPoint.x * scale;
    focusPoint.y = focusPoint.y * scale;

    // add or subtract 10px to account for padding offset
    if (options.doublePos) {
      const leftBuffer = scale === 1 ? -20 : 20;
      xOffset =
        options.doublePos === 1
          ? -(containerBottomLeftX - focusPoint.x) - this.defaultRoundBurstSize + leftBuffer
          : -(containerBottomLeftX - focusPoint.x) + this.defaultRoundBurstSize - 40;
      yOffset = -(containerBottomLeftY - focusPoint.y) - 40;
    } else {
      xOffset = -(containerBottomLeftX - focusPoint.x) - 20;
      yOffset = -(containerBottomLeftY - focusPoint.y) - 40;
    }

    options.count = options.count || defaultCount;

    if (options.soundEffect) this._soundService.playSound(options.soundEffect);//this._soundService.playSound(SOUND_NAMES.Achievement);

    for (let i = 0; i < options.count; i++) {
      const animationEl = this.createAnimationElement(showBehind, burstTheme);

      // Animation motion path
      let upPath: IMotionPath;
      let animationSpeed: number;

      if (burstType === BURST_TYPE.Round) {
        upPath = this.generateRoundUpPath(i, options.count);
        animationSpeed = this.getRandomNumber(1000, 1500) / 2000;
      } else {
        upPath = this.generateUpPath(containerWidth || 0, containerHeight || 0, options.spread || defaultSpread);
        animationSpeed = this.getRandomNumber(1000, 1500) / 1000;
      }

      const downPath = this.generateDownPath(upPath.endPoint, containerHeight || 0);

      // Time for animation
      // Calculate last time base on distance
      let animationSpeedLast = 2 * Math.min(Math.abs(upPath.endPoint[1] / (containerHeight || 0)), 0.8) + this.getRandomNumber(-5, 5) / 10;
      animationSpeedLast = Math.max(animationSpeed, animationSpeedLast);

      const animationData = {
        index: i,
        burstType: burstType,
        options: options,
        animationEl: animationEl,
        frag: frag,
        upPath: upPath,
        downPath: downPath,
        animationSpeed: animationSpeed,
        aSLast: animationSpeedLast,
        xOffset: xOffset,
        yOffset: yOffset,
      };

      this.makeAnimation(animationData);

      container?.appendChild(frag);
    }
  }

  private makeAnimation(data: any): void {
    const tl = gsap.timeline();

    tl.to(data.animationEl, {
      duration: data.animationSpeed,
      motionPath: {
        path: data.upPath.path,
        type: 'cubic',
        offsetX: data.xOffset,
        offsetY: data.yOffset,
      },
      ease: 'power1.out',
      rotation: data.burstType === BURST_TYPE.Default ? this.getRandomNumber(360, 1080) : 0,
    });

    tl.to(data.animationEl, {
      duration: data.aSLast,

      motionPath: {
        path: data.downPath.path,
        type: 'cubic',
        offsetX: data.xOffset,
        offsetY: data.yOffset,
      },
      ease: 'power1.in',
      rotation: data.burstType === BURST_TYPE.Default ? this.getRandomNumber(360, 1080) : 0,
      onComplete: function () {
        if (this['_targets'] && this['_targets'].length > 0) {
          this['_targets'][0].remove();
          if (data.options.count && data.index === data.options.count - 1) {
            if (data.options.callback) data.options.callback();
          }
        }
      },
    });

    data.frag.appendChild(data.animationEl);
  }

  private getTopCoordinates(el: HTMLElement | null): { x: number; y: number } {
    if (!el) return { x: 0, y: 0 };

    const rect = el.getBoundingClientRect();

    // Calculate the top center coordinates
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top - 25; // bump it slightly above the top

    const centerCoords = {
      x: centerX,
      y: centerY,
    };

    return centerCoords;
  }

  private getCenterCoordinates(el: HTMLElement | null): { x: number; y: number } {
    if (!el) return { x: 0, y: 0 };

    const rect = el.getBoundingClientRect();

    // Calculate the center coordinates
    const centerX = rect.left + rect.width / 2;
    const centerY = rect.top + rect.height / 2;

    const centerCoords = {
      x: centerX,
      y: centerY,
    };

    return centerCoords;
  }

  private createAnimationElement(showBehind: boolean, defaultBurstTheme: BURST_THEME = BURST_THEME.Unknown) {
    const el = document.createElement('div');

    const themeId = defaultBurstTheme ? defaultBurstTheme : this._appStateService.level;
    const burstTheme = this._burstThemes.find(b => b.id === themeId);//?? BURST_THEME.Unknown;
    if (!burstTheme) {
      return;
    }

    const randomImageIdx = this.getRandomNumber(0, burstTheme.svgImages.length - 1);
    const svgImage = burstTheme.svgImages[randomImageIdx];

    el.style.backgroundImage = `url("${svgImage}")`;
    el.style.backgroundRepeat = 'no-repeat';
    el.style.width = 65 + 'px';
    el.style.height = 65 + 'px';
    el.style.zIndex = showBehind ? '1' : '9999';
    el.style.position = 'absolute';

    const tol = Math.random() * (1.4 - 0.8) + 0.8;

    el.style.transform = `scale(${burstTheme.scale * tol})`;

    const random = this.getRandomNumber(0, 1);
    if (random) {
      el.style.filter = 'blur(' + Math.abs(10 / 20) + 'px)';
      el.style.opacity = (0.4).toString();
    }

    return el;
  }

  private getRandomNumber(start: number, end: number) {
    const numberOfVariants = Math.floor(end - start + 1);
    return Math.floor(Math.random() * numberOfVariants) + start;
  }

  private generateRoundUpPath(i: number, totalCount: number) {
    const startPoint: Point = [0, 0];
    let radius = this.defaultRoundBurstSize;
    let helper = 1;

    if (this.isDivisibleBy(i, 10)) {
      helper = 3.5;
    } else if (this.isDivisibleBy(i, 6)) {
      helper = 3;
    } else if (this.isDivisibleBy(i, 4)) {
      helper = 1.25;
    } else if (this.isDivisibleBy(i, 2)) {
      helper = 2;
    }

    radius = this.defaultRoundBurstSize / helper;

    const angle = (i / totalCount) * (2 * Math.PI);
    const x = radius * Math.cos(angle);
    const y = radius * Math.sin(angle);

    const endPoint: Point = [x, y];
    const points: Point[] = [startPoint, endPoint];

    return {
      path: `M ${startPoint.join(',')} L ${endPoint.join(',')}`,
      endPoint: endPoint,
      points: points,
    } as IMotionPath;
  }

  private isDivisibleBy(index: number, divisor: number): boolean {
    return index % divisor === 0;
  }

  private generateUpPath(containerWidth: number, containerHeight: number, spread: number) {
    const startPoint: Point = [0, containerHeight * 0.25];
    const endPointY = -this.getRandomNumber(containerHeight * 0.1, containerHeight);
    const endPointX = this.getRandomNumber(-containerWidth * spread, containerWidth * spread);
    const endPoint: Point = [endPointX, endPointY];

    const controlPoint1: Point = [
      this.getRandomNumber(startPoint[0] / 2, startPoint[0]),
      this.getRandomNumber(startPoint[1] / 2, startPoint[1]),
    ];
    const controlPoint2: Point = [this.getRandomNumber(endPoint[0], endPoint[0]), this.getRandomNumber(endPoint[1], endPoint[1])];
    const points: Point[] = [startPoint, controlPoint1, controlPoint2, endPoint];

    return {
      path: `M ${startPoint.join(',')} C ${controlPoint1.join(',')} ${controlPoint2.join(',')} ${endPoint.join(',')}`,
      endPoint: endPoint,
      points: points,
    } as IMotionPath;
  }

  private generateDownPath(startPoint: Point, containerHeight: number) {
    let endPointX = 0;
    if (Math.abs(startPoint[1]) > containerHeight / 2) {
      endPointX = this.getRandomNumber(startPoint[0], startPoint[0]);
    } else {
      endPointX = this.getRandomNumber(startPoint[0] / 2, startPoint[0]);
    }

    const floor = containerHeight * 2;
    const endPoint: Point = [endPointX, floor];
    const controlPoint1: Point = [this.getRandomNumber(startPoint[0], startPoint[0]), floor];
    const controlPoint2: Point = [this.getRandomNumber(endPoint[0], endPoint[0]), floor];

    return {
      path: `M ${startPoint.join(',')} C ${controlPoint1.join(',')} ${controlPoint2.join(',')} ${endPoint.join(',')}`,
    };
  }
}