import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { AuthGuard } from './guards/auth.guard';
import { environment } from '@env/environment';
import { PrimaryLayoutComponent } from '@app/layouts/primary-layout/primary-layout.component';
import { DashboardComponent } from '@app/pages/dashboard/dashboard.component';
import { ActivityLayoutComponent } from '@app/layouts/activity-layout/activity-layout.component';
import {
  EchoFindWordsActivityComponent,
  MakeAChangeActivityComponent,
  NotSoTrickyActivityComponent,
  WordFindFunActivityComponent,
  WorldOfWordsActivityComponent,
} from '@app/pages/activities';
import { LoginComponent } from '@app/pages/login/login.component';
import { ValidateParamsResolver } from '@app/shared/services/data-resolver.service';
import { ACTIVITY_IDS } from '@app/shared/constants/activity-ids';
import { LoginSuccessComponent } from './pages/login/login-success.component';

export enum APP_ROUTES {
  NOT_FOUND = 'notfound',
}

const activityChildren = [
  {
    path: ACTIVITY_IDS.NotSoTricky,
    resolve: {
      isValidParams: ValidateParamsResolver,
    },
    component: NotSoTrickyActivityComponent,
  },
  {
    path: ACTIVITY_IDS.MakeAChange,
    resolve: {
      isValidParams: ValidateParamsResolver,
    },
    component: MakeAChangeActivityComponent,
  },
  {
    path: ACTIVITY_IDS.EchoFindWords,
    resolve: {
      isValidParams: ValidateParamsResolver,
    },
    component: EchoFindWordsActivityComponent,
  },
  {
    path: ACTIVITY_IDS.WordFindFun,
    resolve: {
      isValidParams: ValidateParamsResolver,
    },
    component: WordFindFunActivityComponent,
  },
  {
    path: ACTIVITY_IDS.WorldOfWords,
    resolve: {
      isValidParams: ValidateParamsResolver,
    },
    component: WorldOfWordsActivityComponent,
  },
];

// todowilson: child routes, ability to route while obscuring URL params
const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    canActivate: [AuthGuard],
    component: PrimaryLayoutComponent,
    children: [
      {
        path: '',
        component: DashboardComponent,
        loadChildren: () => import('@app/pages/dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
    ],
  },
  {
    path: 'login',
    component: PrimaryLayoutComponent,
    children: [
      {
        path: '',
        component: LoginComponent,
        loadChildren: () => import('@app/pages/login/login.module').then((m) => m.LoginModule),
      },
    ],
  },
  {
    path: 'login-success',
    component: LoginSuccessComponent,
  },
  {
    path: 'playground',
    canActivate: [() => environment.API_URL.includes('localhost')],
    loadChildren: () => import('@app/playground/playground.module').then((m) => m.PlayGroundModule),
  },
  {
    path: 'activities-bypass/audioqa',
    canActivate: [() => !environment.production],
    loadChildren: () => import('@app/audioqa/audioqa.module').then((m) => m.AUDIOQAModule),
  },
  {
    path: 'activities',
    canActivate: [AuthGuard],
    component: ActivityLayoutComponent,
    children: activityChildren,
  },
  {
    path: 'activities-bypass',
    canActivate: [() => !environment.production],
    component: ActivityLayoutComponent,
    children: activityChildren,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
