import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Observable } from 'rxjs';
import { IAppEvent } from '@app/shared/types/app-event-interface';
import { AppStateService } from '@app/shared/services/app-state.service';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AppHelpers } from '@app/shared/helpers/app-helpers';
import { ButtonModule } from 'primeng/button';
import { NgxJsonViewerModule } from 'ngx-json-viewer';

interface IInfoMessage {
  time: string;
  message: string;
  object: any;
}

@Component({
  selector: 'app-debug-console',
  standalone: true,
  imports: [CommonModule, ButtonModule, NgxJsonViewerModule],
  templateUrl: './debug-console.component.html',
  styleUrls: ['./debug-console.component.scss'],
  animations: [],
})
export class DebugConsoleComponent implements OnInit {
  protected appEvent$: Observable<IAppEvent>;
  protected showPage = false;
  protected isFullMode = false;

  private readonly _appStateService = inject(AppStateService);
  private readonly _destroyRef = inject(DestroyRef);

  protected messages: IInfoMessage[] = [];

  constructor() {}

  ngOnInit() {
    this.showPage = AppHelpers.isDebugMode();

    if (!this.showPage) {
      return;
    }

    this.appEvent$ = this._appStateService.appEvent$;

    this.appEvent$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event.area === APP_EVENT_AREAS.SHOW_INFO && event.message) {
        const info: IInfoMessage = {
          time: new Date().toLocaleTimeString(),
          message: event.message,
          object: event.object,
        };
        this.messages.unshift(info);
        this.isFullMode = true;
      }
    });
  }

  clear() {
    this.messages.length = 0;
  }

  toggleVisibility() {
    this.isFullMode = !this.isFullMode;
  }
}
