import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { COMMA_SEPARATOR } from '@app/shared/constants/activity-constants';
import { SOUND_NAMES } from '@app/shared/constants/sound-enums';
import { AnimationHelpers } from '@app/shared/helpers/animation';
import { ANIMATIONS } from '@app/shared/constants/animation-enums';
import { DELAY } from '@app/shared/constants/delay-enums';
import { AppHelpers } from '@app/shared/helpers/app-helpers';
import { MISTAKE_CONTEXT, PUZZLE_STATUS } from '@app/shared/constants/session-enums';
import { IPrefixPuzzle, IWord } from '../../types/wow-interfaces';
import { IWorldOfWordsDataset } from '../../types/world-of-words-dataset-interface';
import { IPuzzleCompleteEvent } from '@app/shared/types/puzzle-complete-event-interface';
import { PuzzleTypeBaseComponent } from '@app/pages/activities/puzzle-type-base/puzzle-type-base.component';

@Component({
  selector: 'app-wow-puzzle-type-0',
  templateUrl: './puzzle-type-0.component.html',
  styleUrls: ['./puzzle-type-0.component.scss'],
  imports: [CommonModule],
  standalone: true,
  providers: [],
})
export class WOWPuzzleType0Component extends PuzzleTypeBaseComponent {
  @Input() currentWorldOfWordsDataset: IWorldOfWordsDataset | undefined;
  @Input() expressions: Map<number, string>;
  @Output() puzzleComplete = new EventEmitter<IPuzzleCompleteEvent>();

  // Puzzle Type 0
  protected prefixPuzzles: IPrefixPuzzle[] = [];
  protected activePrefix: IPrefixPuzzle | undefined;
  protected solvedWords: IWord[] = [];
  private _totalMistakes = 0;
  protected wordDiv = 'wordDiv';

  constructor() {
    super();
    this.emitCall = this.puzzleComplete;
  }

  initPuzzle() {
    this.setExpressions(this.expressions);
    this.startPuzzleMetrics();
    this.setMaxTries(this.currentWorldOfWordsDataset?.maxTries)
    this.buildPrefixes();
    this.buildChoices();
    this.soundService.playExpression(this.getExpression(1), () => { });
  }


  // Puzzle Type 0 ---------------

  buildPrefixes() {
    this.prefixPuzzles.length = 0;
    this.solvedWords.length = 0;
    this._totalMistakes = 0;

    const syllables = this.currentWorldOfWordsDataset?.syllables?.trimAll().split(COMMA_SEPARATOR);

    syllables?.forEach((s, idx) => {
      const prefix: IWord = {
        id: AppHelpers.generateId(),
        index: idx,
        word: s,
        display: s,
        valid: false,
        active: idx === 0,
        solved: false,
        error: false,
        correct: false,
      };
      const prefixPuzzle: IPrefixPuzzle = {
        id: AppHelpers.generateId(),
        prefix: prefix,
        choices: [],
        solved: false,
      };
      this.prefixPuzzles.push(prefixPuzzle);
    });
  }

  buildChoices() {
      // now build out the valid choices for each prefix
      const choices = this.currentWorldOfWordsDataset?.choices.trimAll().split(COMMA_SEPARATOR);

      this.prefixPuzzles.forEach((puzzle) => {
        // shuffle content
        choices?.sort(() => {
          return 0.5 - Math.random();
        });

        // remove any choice that belongs to a different prefix puzzle
        choices?.forEach((theChoice, idx) => {
          const choice: IWord = {
            id: AppHelpers.generateId(),
            index: idx,
            word: theChoice,
            display: (() => {
              let text = '';
              this.prefixPuzzles.forEach((p) => {
                if (theChoice.startsWith(p.prefix.word)) {
                  text = text.length ? text : AppHelpers.removeFirstOccurrence(theChoice, p.prefix.word);
                }
              });
              return text.length ? text : theChoice;
            })(),
            valid: (() => {
              return theChoice.startsWith(puzzle.prefix.word);
            })(),
            active: false,
            solved: false,
            error: false,
            correct: false,
          };
          // if there is already a valid one for this choice do not add this as a choice.
          const validExists = puzzle.choices.find((c) => c.display === choice.display && c.id != choice.id && c.valid);
          if (!validExists) {
            puzzle.choices.push(choice);
            if (choice.valid) {
              // is there already a choice which has same display and not valid ?, then remove it.
              const invalidChoice = puzzle.choices.find((c) => c.display === choice.display && !c.valid);
              if (invalidChoice) {
                puzzle.choices = puzzle.choices.filter((c) => c.id != invalidChoice.id);
              }
            } else {
              const dupChoice = puzzle.choices.find((c) => c.display === choice.display && c.id != choice.id && !c.valid);
              if (dupChoice) {
                puzzle.choices = puzzle.choices.filter((c) => c.id != dupChoice.id);
              }
            }
          }
        });
      });

      this.activePrefix = this.prefixPuzzles.find((p) => p.prefix.active);
  }

  // puzzleType 0 : select the 2nd syllable that matches 1st syllable
  onSyllableChoiceSelected(choice: IWord) {
    if (this.validatingPuzzle) {
      return;
    }

    this.activePrefix?.choices.map((s) => (s.active = false));

    this.validatingPuzzle = true;
    if (!choice.solved) {
      choice.active = true;

      if (choice.valid) {
        // is this a correct choice?

        this.animateSyllableChoice(choice, () => {
          // are all syllable 2 solved?
          choice.active = false;
          if (!this.getNextValidChoice()) {
            // only passes if they did not exceed maxtries for all syllable choices
            this.completePuzzle(this._totalMistakes >= this.maxTries ? PUZZLE_STATUS.FAIL : PUZZLE_STATUS.PASS);
          } else this.validatingPuzzle = false;
        });
      } else {
        choice.error = true;
        this.addUserMistakeDetails({
          content: choice.display,
          context: MISTAKE_CONTEXT.Wrong_Word,
        });
        AnimationHelpers.animate(choice.id, ANIMATIONS.ShakeX);

        this._totalMistakes++;

        if (this.hasTriesExceeded()) {
          this.soundService.playSound(SOUND_NAMES.Incorrect);
          this.addMistake();

          // auto solve
          this.soundService.playExpression(this.getExpression(4), () => {
            choice.error = false;
            choice.active = false;
            const targetChoice = this.activePrefix?.choices.find((c) => !c.solved && c.valid);
            this.animateUnsolvedSyllableChoices(targetChoice, () => {
              if (!this.getNextValidChoice()) {
                // only passes if they did not exceed maxtries for all syllable choices
                this.validatingPuzzle = true;
                this.completePuzzle(this._totalMistakes >= this.maxTries ? PUZZLE_STATUS.FAIL : PUZZLE_STATUS.PASS);
              } else {
                this.validatingPuzzle = false;
              }
            });
          });
        } else {
          this.soundService.playSound(SOUND_NAMES.Incorrect, () => {
            this.soundService.playExpression(this.getExpression(3), () => { });
            this.validatingPuzzle = false;
            choice.error = false;
            choice.active = false;
          });
        }
      }
    }
  }

  solveChoice(choice: IWord | undefined) {
    if (!choice) {
      return;
    }
    choice.solved = true;
    const solvedWord: IWord = {
      // add word to solved Words
      id: AppHelpers.generateId(),
      index: 0,
      word: '',
      display: `${choice.word}`,
      active: false,
      valid: true,
      solved: true,
      error: false,
      correct: false,
    };
    this.solvedWords.push(solvedWord);

    setTimeout(() => {
      choice.active = false;
    }, DELAY.SMALL);
  }

  getNextValidChoice() {
    const nextValidChoice = this.activePrefix?.choices.filter((s) => s.valid).find((s) => !s.solved);
    let continuePuzzle = true;

    if (!nextValidChoice) {
      // user has selected all the correct choices for the prefix
      // is there a next prefix then ?
      this.resetTries();
      if (this.activePrefix) {
        this.activePrefix.solved = true;
        const nextPrefix = this.prefixPuzzles.find((p) => !p.solved);
        if (!nextPrefix) {
          continuePuzzle = false;
        } else {
          this.activePrefix = nextPrefix;
          setTimeout(() => {
            AnimationHelpers.animate(nextPrefix.id, ANIMATIONS.Bounce);
          }, DELAY.S1);
        }
      }
    }

    return continuePuzzle;
  }

  animateSyllableChoice(choice: IWord | undefined, callback?: () => void | undefined) {
    if (!choice) {
      if (callback) callback();
      return;
    }

    this.soundService.playSound(SOUND_NAMES.Correct);
    this.soundService.playExpression(choice.word, () => {
      // find the last word in list, else top of WordDiv
      const lastSolvedWord = Array.from(this.solvedWords.values()).pop();
      const targetId = lastSolvedWord ? lastSolvedWord.id : this.wordDiv;

      this.anim.animateTo(choice?.id, targetId, () => {
        this.solveChoice(choice);
        if (callback) callback();
      });
    });
  }

  animateUnsolvedSyllableChoices(choice: IWord | undefined, callback?: () => void | undefined) {
    if (!choice) {
      if (callback) callback();
      return;
    }
    this.soundService.playExpression(choice.word, () => {
      this.anim.animateTo(choice?.id, this.wordDiv, () => {
        this.solveChoice(choice);
        choice = this.activePrefix?.choices.find((c) => !c.solved && c.valid);
        this.animateUnsolvedSyllableChoices(choice, callback);
      });
    });
  }

  // End Puzzle Type 0 ---------------
}
