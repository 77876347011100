import { CommonModule } from '@angular/common';
import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { COMMA_SEPARATOR, EXCLAMATION_MARK, PERIOD, QUESTION_MARK } from '@app/shared/constants/activity-constants';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { DELAY } from '@app/shared/constants/delay-enums';
import { AppStateService } from '@app/shared/services/app-state.service';
import { IAppEvent } from '@app/shared/types/app-event-interface';
import { Observable } from 'rxjs';

interface IWord {
  text: string;
  active: boolean;
  delay: number;
  hidden: boolean;
}

@Component({
  selector: 'app-exp-viewer',
  templateUrl: './expression-viewer.component.html',
  styleUrls: ['./expression-viewer.component.scss'],
  imports: [CommonModule],
  standalone: true
})
export class ExpressionViewerComponent implements OnInit {

  protected words: IWord[] = [];
  protected appEvent$: Observable<IAppEvent>;

  protected display: boolean;
  protected toggled: boolean = false;



  private readonly _appStateService = inject(AppStateService);
  private readonly _delayChar = '~';
  private readonly _destroyRef = inject(DestroyRef);

  protected currentLevel: number | undefined = this._appStateService.level;


  ngOnInit() {
    this.appEvent$ = this._appStateService.appEvent$;

    this.appEvent$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event.area === APP_EVENT_AREAS.VIEW_EXPRESSION) {
        this.display = true;
        this.words = [];
        this.splitExpression(event.message ?? '');
        this.animate(() => {
          setTimeout(() => {
            this.display = false;
            if (event.callback) event.callback();
          }, DELAY.S1);
        });
      }
    });
  }

  splitExpression(expression: string) {
    const words = expression.split(' ');
    let timeMark = 0;
    let prevWord = '';
    words.forEach((w) => {
      const word: IWord = {
        text: w,
        active: false,
        delay: 0,
        hidden: false
      };
      timeMark += this.getWordDelay(w, prevWord);
      word.delay = timeMark;
      this.words.push(word);
      prevWord = w;
    });
  }

  getWordDelay(text: string, prevWord: string) {
    let delay = 0;
    const charDelay = 15;
    const charDelayTilde = 400;
    const len = text.length;

    if (!text.includes(this._delayChar)) {
      delay = len * charDelay;
    } else {
      delay = len * charDelayTilde;
    }

    if (prevWord.includes(COMMA_SEPARATOR) || prevWord.includes(PERIOD) || prevWord.includes(EXCLAMATION_MARK) || prevWord.includes(QUESTION_MARK)) {
      delay += 370;
    } else if (prevWord.includes(this._delayChar)) {
      delay = len * charDelayTilde;
    }
    return delay;

  }


  animate(callback?: () => void | undefined) {
    let wordCompleteCount = 0;
    this.words.forEach((w) => {
      setTimeout(() => {
        w.active = true;
        if (++wordCompleteCount === this.words.length) {
          if (callback) callback();
        }
      }, w.delay);
    });
  }
}
