import { Component, EventEmitter, inject, Input, Output, QueryList, ViewChildren } from '@angular/core';
import { IEchoFindWordsDataset } from '../../types/echo-find-words-dataset-interface';
import { IPuzzleCompleteEvent } from '@app/shared/types/puzzle-complete-event-interface';
import { ILetterPanel } from '@app/shared/components/letter-panel/types/letter-panel-interface';
import { PuzzleTypeBaseComponent } from '@app/pages/activities/puzzle-type-base/puzzle-type-base.component';
import { AppHelpers } from '@app/shared/helpers/app-helpers';
import { ChoiceBankComponent } from '@app/shared/components/choice-bank/choice-bank.component';
import { LetterPanelComponent } from '@app/shared/components/letter-panel/letter-panel.component';
import { CommonModule } from '@angular/common';
import { MISTAKE_CONTEXT, PUZZLE_STATUS } from '@app/shared/constants/session-enums';
import { SOUND_NAMES } from '@app/shared/constants/sound-enums';
import { ANIMATIONS } from '@app/shared/constants/animation-enums';
import { AnimationHelpers } from '@app/shared/helpers/animation';
import { BURST_TYPE } from '@app/shared/types/animation-burst.interface';
import { DELAY } from '@app/shared/constants/delay-enums';
import { AppStateService } from '@app/shared/services/app-state.service';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';

enum PUZZLE_LEVEL {
  Zippy = 1,
  Ollie,
  Domino,
}

@Component({
  selector: 'app-bwe-puzzle-type-1',
  standalone: true,
  imports: [LetterPanelComponent, ChoiceBankComponent, CommonModule],
  templateUrl: './puzzle-type-1.component.html',
  styleUrl: './puzzle-type-1.component.scss',
})
export class BWEPuzzleType1Component extends PuzzleTypeBaseComponent {
  @Input() currentBuildEchoDataset: IEchoFindWordsDataset | undefined;
  @Input() expressions: Map<number, string>;
  @Input() positionCommands: Map<number, string>;

  @Output() puzzleComplete = new EventEmitter<IPuzzleCompleteEvent>();
  @Output() subPartComplete = new EventEmitter();

  @ViewChildren(LetterPanelComponent) activeWord!: QueryList<LetterPanelComponent>;

  private readonly _appState = inject(AppStateService);

  protected letterPanelsActiveWord: ILetterPanel[] = [];
  protected letterPanelsChoices: ILetterPanel[] = [];
  protected puzzleLevelEnum = PUZZLE_LEVEL;
  protected currentLevel: number | undefined = this._appState.level;


  private targetPanel: ILetterPanel = this.letterPanelsActiveWord.filter((lp) => !lp.complete)[0];
  private whiteboxClicked: boolean = false;
  private spellingOptionMode: boolean = false;

  private vowelConsonant = false;

  constructor() {
    super();
    this.emitCall = this.puzzleComplete;
  }

  initPuzzle() {
    this.startPuzzleMetrics();
    this.setExpressions(this.expressions);
    this.initActiveWord();
    this.initChoicePanels();
    this.setMaxTries(this.currentBuildEchoDataset?.maxTries)

    this.targetPanel = this.letterPanelsActiveWord.filter((lp) => !lp.complete)[0];
    this.validatingPuzzle = false;
    this.whiteboxClicked = false;
    this.spellingOptionMode = false;
    if (this.currentBuildEchoDataset?.spellingOptions.includes("_e]")) this.vowelConsonant = true;

    this.soundService.playExpression(this.getExpression(1));
    this.appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.PUZZLE_CLUE,
      puzzleClueExpression: this.getExpression(1) ?? '',
    });
  }

  initActiveWord() {
    if (this.currentBuildEchoDataset) {
      AppHelpers.buildTargetWordPanels(this.currentBuildEchoDataset.word, this.letterPanelsActiveWord);
    }
  }

  initChoicePanels(spellOpt = false) {
    if (this.currentBuildEchoDataset && !spellOpt) {
      AppHelpers.buildChoicePanels(this.currentBuildEchoDataset.choices, this.letterPanelsChoices);
    } else if (this.currentBuildEchoDataset && spellOpt) {
      AppHelpers.buildChoicePanels(this.currentBuildEchoDataset.spellingOptions, this.letterPanelsChoices);
    }
  }

  onLetterChoiceSelected(selectedPanel: ILetterPanel) {
    if (this.validatingPuzzle) {
      return;
    }

    this.validatingPuzzle = true;

    selectedPanel.active = true;


    if (selectedPanel.isGrouped && this.currentBuildEchoDataset?.spellingOptions.split(",").some(so => so.endsWith("_e]"))) {
      this.handleGroupedChoiceEvent(selectedPanel);
      return;
    }

    if (this.targetPanel.index === this.currentBuildEchoDataset?.position && selectedPanel.isWhiteBox) {
      selectedPanel.success = true;
      this.processWhiteBox(selectedPanel);
      return;
    }

    if (selectedPanel.isCorrectAnswer &&
      selectedPanel.content === this.targetPanel.content &&
      selectedPanel.gluedSound === this.targetPanel.gluedSound &&
      selectedPanel.suffix === this.targetPanel.suffix &&
      selectedPanel.vowel === this.targetPanel.vowel) {

      selectedPanel.success = true;

      this.animatePanel(selectedPanel, () => {
        this.soundService.playSound(SOUND_NAMES.Correct, () => {
          selectedPanel.success = false;
          selectedPanel.active = false;
        });

        if (this.spellingOptionMode) {
          this.targetPanel.isWhiteBox = false;
        }

        if (this.whiteboxClicked && this.vowelConsonant) {
          this.transitionSpellOpt();
          this.validatingPuzzle = false;
        }

        if (!this.getNextTarget()) {
          if (this.whiteboxClicked) {
            this.subPartComplete.emit({ newPuzzle: true, passed: !this._selfCorrected });
            setTimeout(() => {
              this.transitionSpellOpt();
              this.validatingPuzzle = false;
            }, DELAY.S1)
            return;
          } else {
            this.soundService.playExpression(this.getExpression(2), () => {
              const burstBehind = this.letterPanelsActiveWord.find((lp, i) => i === Math.floor(this.letterPanelsActiveWord.length / 2));
              this.burst.animate({ soundEffect: SOUND_NAMES.Achievement, offsetId: burstBehind?.id }, BURST_TYPE.Round);
              setTimeout(() => {
                this.subPartComplete.emit({ newPuzzle: true, passed: !this._selfCorrected });
                setTimeout(() => {
                  this.completePuzzle(this._selfCorrected ? PUZZLE_STATUS.FAIL : PUZZLE_STATUS.PASS);
                }, DELAY.S2)
              }, DELAY.S2);
            });
          }
        } else {
          this.subPartComplete.emit(false);
          this.validatingPuzzle = false;
        }
      });
    } else {
      selectedPanel.error = true;

      this.addUserMistakeDetails({
        content: selectedPanel.content,
        context: MISTAKE_CONTEXT.Wrong_Word,
      });

      AnimationHelpers.animate(selectedPanel.id, ANIMATIONS.ShakeX);

      if (this.hasTriesExceeded()) {
        this.soundService.playSound(SOUND_NAMES.Incorrect);
        this.addMistake();

        if (!this._selfCorrected) {
          this.subpartSelfCorrected(true);
        }

        let correctPanel;
        if (this.spellingOptionMode && !this.vowelConsonant) {
          correctPanel = this.letterPanelsChoices.filter(lp => {
            if (this.currentBuildEchoDataset?.spellingOptions.includes("_e]")) return lp.content === this.targetPanel?.content && lp.isGrouped;
            return lp.content === this.targetPanel?.content && !lp.isGrouped
          })[0]
        } else {
          correctPanel = this.letterPanelsChoices.find((lp) => lp.isCorrectAnswer && lp.content === this.targetPanel?.content);
        }

        this.soundService.playExpression(this.spellingOptionMode ? this.getPositionCommand(3) : this.getExpression(4), () => {
          selectedPanel.error = false;
          selectedPanel.active = false;

          if (correctPanel) {
            if (this.spellingOptionMode && this.currentBuildEchoDataset?.spellingOptions.includes("_e]")) {
              this.handleGroupedChoiceEvent(correctPanel, true)

              if (!this.getNextTarget()) {
                this.validatingPuzzle = true;
                setTimeout(() => {
                  this.subPartComplete.emit({ newPuzzle: true, passed: !this._selfCorrected });
                  setTimeout(() => {
                    this.completePuzzle(this._selfCorrected ? PUZZLE_STATUS.FAIL : PUZZLE_STATUS.PASS);
                  }, DELAY.S1)
                }, DELAY.S2);
              } else {
                this.subPartComplete.emit(false);
                this.validatingPuzzle = false;
                return;
              }
            }
            this.animatePanel(correctPanel, () => {
              if (this.whiteboxClicked && this.vowelConsonant) {
                this.transitionSpellOpt();
                this.validatingPuzzle = false;
              }
              if (this.spellingOptionMode) this.targetPanel.isWhiteBox = false;
              if (!this.getNextTarget()) {
                if (this.whiteboxClicked) {
                  this.subPartComplete.emit({ newPuzzle: true, passed: !this._selfCorrected });
                  setTimeout(() => {
                    this.transitionSpellOpt();
                    this.validatingPuzzle = false;
                  }, DELAY.S1)
                  return;
                } else {
                  this.validatingPuzzle = true;
                  setTimeout(() => {
                    this.subPartComplete.emit({ newPuzzle: true, passed: !this._selfCorrected });
                    setTimeout(() => {
                      this.completePuzzle(this._selfCorrected ? PUZZLE_STATUS.FAIL : PUZZLE_STATUS.PASS);
                    }, DELAY.S1)
                  }, DELAY.S2);
                }
              } else {
                this.subPartComplete.emit(false);
                this.validatingPuzzle = false;
              }
            });
          }
        });
      } else {
        this.soundService.playSound(SOUND_NAMES.Incorrect, () => {
          if (this.targetPanel.index === this.currentBuildEchoDataset?.position && !this.spellingOptionMode) {
            const correctPanel = this.letterPanelsChoices.filter((lp) => lp.isWhiteBox)[0];
            setTimeout(() => {
              selectedPanel.error = false;
              selectedPanel.active = false;
            }, DELAY.S1);
            this.processWhiteBox(correctPanel);
          } else {
            this.soundService.playExpression(this.spellingOptionMode ? this.getPositionCommand(2) : this.getExpression(3), () => {
              selectedPanel.error = false;
              selectedPanel.active = false;
              this.validatingPuzzle = false;
            });
          }
        });
      }
    }
  }

  private animatePanel(panel: ILetterPanel, callback?: () => void | undefined) {
    if (!panel) {
      if (callback) callback();
      return;
    }

    this.anim.animateTo(panel.id, this.targetPanel?.id, () => {
      if (this.targetPanel) this.targetPanel.complete = true;
      if (callback) callback();
    });
  }

  getNextTarget() {
    this.targetPanel = this.letterPanelsActiveWord.filter((lp) => !lp.complete)[0];

    if (this.spellingOptionMode) {
      this.targetPanel = this.letterPanelsActiveWord.filter((lp) => lp.isWhiteBox)[0];
      if (this.vowelConsonant) {
        this.targetPanel = this.letterPanelsActiveWord.filter((lp) => !lp.complete)[0];
        this.vowelConsonant = false;
      }
    }

    if (!this.targetPanel) {
      this.resetTries();
      return false;
    }
    this.resetTries();
    return true;
  }

  processWhiteBox(panel: ILetterPanel) {
    this.whiteboxClicked = true;
    this.animatePanel(panel, () => {
      this.targetPanel.isWhiteBox = true;
      panel.success = false;
      panel.active = false;
      this.soundService.playExpression(this.getPositionCommand(1), () => {
        if (!this.getNextTarget()) {
          this.transitionSpellOpt();
          this.validatingPuzzle = false;
        } else {
          this.validatingPuzzle = false;
        }
      });
    });
  }

  transitionSpellOpt() {
    this.initChoicePanels(true);
    this.spellingOptionMode = true;
    this.getNextTarget();
    this.whiteboxClicked = false;
  }

  getPositionCommand(key: number) {
    return this.positionCommands.get(key);
  }

  private handleGroupedChoiceEvent(selectedPanel: ILetterPanel, autosolve: boolean = false) {
    const groupedPanels = this.letterPanelsChoices.filter((lp) => lp.isGrouped);

    if (!groupedPanels) {
      return;
    }

    const targetPanelIndex = this.letterPanelsActiveWord.indexOf(this.targetPanel);
    const lastPanel = this.letterPanelsActiveWord[targetPanelIndex + 2];

    if (groupedPanels[0].content === this.targetPanel?.content &&
      groupedPanels[1].content === "_" &&
      groupedPanels[2].content === lastPanel?.content || autosolve) {
      this.anim.animateTo(groupedPanels[0].id, this.targetPanel?.id, () => {
        if (this.targetPanel) this.targetPanel.complete = true;
        this.targetPanel.isWhiteBox = false;
      });
      setTimeout(() => {
        this.anim.animateTo(groupedPanels[2].id, lastPanel.id, () => {
          lastPanel.complete = true;
          if (!autosolve) {
            this.soundService.playExpression(this.getExpression(2), () => {
              const burstBehind = this.letterPanelsActiveWord.find((lp, i) => i === Math.floor(this.letterPanelsActiveWord.length / 2));
              this.burst.animate({ soundEffect: SOUND_NAMES.Achievement, offsetId: burstBehind?.id }, BURST_TYPE.Round);
              setTimeout(() => {
                this.subPartComplete.emit({ newPuzzle: true, passed: !this._selfCorrected });
                setTimeout(() => {
                  this.completePuzzle(this._selfCorrected ? PUZZLE_STATUS.FAIL : PUZZLE_STATUS.PASS);
                }, DELAY.S2)
              }, DELAY.S2);
            });
          } else {
            setTimeout(() => {
              this.subPartComplete.emit({ newPuzzle: true, passed: !this._selfCorrected });
              setTimeout(() => {
                this.completePuzzle(this._selfCorrected ? PUZZLE_STATUS.FAIL : PUZZLE_STATUS.PASS);
              }, DELAY.S2)
            }, DELAY.S2);
          }
        });
      }, DELAY.S2);
    }
  }
}
