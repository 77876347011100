import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { fixWebmDuration } from "@fix-webm-duration/fix";


@Injectable({
  providedIn: 'root',
})
export class AudioRecordingService {
  private audioContext: AudioContext | null = null;
  private analyser: AnalyserNode | null = null;
  private isAnalyzing: boolean = true;
  private _startTime = Date.now();
  public audioData$ = new Subject<Uint8Array>();


  recordAudio(): Promise<{
    start: () => void;
    stop: () => Promise<{ audioBlob: Blob; audioUrl: string; play: () => void }>
    pause: () => void;
    resume: () => void;
  }> {
    return new Promise((resolve) => {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        const mediaRecorder = new MediaRecorder(stream);
        const audioChunks: BlobPart[] = [];

        mediaRecorder.addEventListener('dataavailable', (event) => {
          audioChunks.push(event.data);
        });

        this.audioContext = new AudioContext();
        const source = this.audioContext.createMediaStreamSource(stream);
        this.analyser = this.audioContext.createAnalyser();
        this.analyser.fftSize = 2048;
        source.connect(this.analyser);
        const dataArray = new Uint8Array(this.analyser.frequencyBinCount);

        // Function to emit frequency data
        const updateFrequencyData = () => {
          if (this.analyser && this.isAnalyzing) {
            this.analyser.getByteFrequencyData(dataArray);
            this.audioData$.next(dataArray);
          }
          requestAnimationFrame(updateFrequencyData);
        };

        const start = () => {
          mediaRecorder.start();
          this.isAnalyzing = true;
          this._startTime = Date.now();
          updateFrequencyData();
        };

        const stop = (): Promise<{ audioBlob: Blob; audioUrl: string; play: () => void }> => {
          return new Promise((resolve) => {
            mediaRecorder.onstop = async () => {
              let audioBlob = new Blob(audioChunks, { type: 'audio/mpeg-3' });
              const duration = Date.now() - this._startTime;
              audioBlob = await fixWebmDuration(audioBlob, duration);  // Must add duration cuz of Chrome bug not saving duration! (https://issues.chromium.org/issues/40482588)
              const audioUrl = URL.createObjectURL(audioBlob);
              const audio = new Audio(audioUrl);
              // play is only used for testing
              const play = () => {
                audio.play();
              };
              resolve({ audioBlob, audioUrl, play });
            };

            if (this.audioContext) {
              this.audioContext.close();
              this.audioContext = null;
              this.analyser = null;
            }

            mediaRecorder.stop();
            this.isAnalyzing = false;
          });
        };

        const pause = () => {
          if (mediaRecorder.state === 'recording') {
            mediaRecorder.pause();
            this.isAnalyzing = false;
          }
        };

        const resume = () => {
          if (mediaRecorder.state === 'paused') {
            mediaRecorder.resume();
            this.isAnalyzing = true;
            updateFrequencyData();
          }
        };

        resolve({ start, stop, pause, resume });
      });
    });
  }
}
