import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ANIMATIONS } from '@app/shared/constants/animation-enums';
import { AnimationHelpers } from '@app/shared/helpers/animation';
import { SoundService } from '@app/shared/services/sound.service';
import { SOUND_NAMES } from '@app/shared/constants/sound-enums';
import { BehaviorSubject, Observable } from 'rxjs';
import { IAppEvent } from '@app/shared/types/app-event-interface';
import { AppStateService } from '@app/shared/services/app-state.service';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DELAY } from '@app/shared/constants/delay-enums';
import { AnimationBurst } from '@app/shared/services/animation-burst.service';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { SCORE_RANGE, ScoringService } from '@app/shared/services/scoring.service';
import { BURST_TYPE } from '@app/shared/types/animation-burst.interface';

@Component({
  selector: 'app-activity-complete',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './activity-complete.component.html',
  styleUrls: ['./activity-complete.component.scss'],
  animations: [],
})
export class ActivityCompleteComponent implements OnInit {
  protected appEvent$: Observable<IAppEvent>;
  protected bucketShadowImage = '';
  protected bucketImage = '';
  protected bucketImage1 = '';
  protected bucketImage2 = '';
  protected bucketImage3 = '';
  protected showPage = false;
  protected level: number = 1;
  protected SCORE_RANGE_ENUM = SCORE_RANGE;
  protected scoreRange: number = SCORE_RANGE.UNKNOWN;

  private readonly _appStateService = inject(AppStateService);
  private readonly _soundService = inject(SoundService);
  private readonly _scoringService = inject(ScoringService);
  private readonly _destroyRef = inject(DestroyRef);
  protected theBurst = inject(AnimationBurst);
  protected showBackToDashboardDialog = false;
  protected isTextToSpeechPlaying$: BehaviorSubject<boolean>;

  constructor(private readonly _router: Router) {
    this.appEvent$ = this._appStateService.appEvent$;

    this._appStateService.currentActivity$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((activity) => {

      this.bucketShadowImage = `/assets/${activity.theme.completedPage.bucketShadow}`;
      this.bucketImage = `/assets/${activity.theme.completedPage.bucket}`;
      this.bucketImage1 = this.bucketImage2 = this.bucketImage3 = this.bucketShadowImage;

    });

    this.appEvent$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event.area === APP_EVENT_AREAS.ACTIVITY_COMPLETE) {
        // hides the Keyboard Dialog buttons page
        this._appStateService.appEvent$.next({
          area: APP_EVENT_AREAS.PUZZLE_CLUE,
          puzzleClueExpression: '',
        });

        this.resetState();
        this.showPage = event.showActivityComplete ? event.showActivityComplete : false;
        this.level = this._appStateService.level;
        this.scoreRange = SCORE_RANGE.UNKNOWN;



        setTimeout(() => {
          // fill in images based upon score.
          this.scoreRange = this._scoringService.getScoreRange();
          this.burst();

          setTimeout(() => {
            // animate 1 bucket at a time
            switch (this.scoreRange) {
              case SCORE_RANGE.LOW:
                this.animateBucket(1, true);
                this.animateBucket(2);
                this.animateBucket(3);
                break;
              case SCORE_RANGE.MEDIUM:
                this.animateBucket(1, true);
                this.animateBucket(2, true);
                this.animateBucket(3);
                break;
              case SCORE_RANGE.HIGH:
                this.animateBucket(1, true);
                this.animateBucket(2, true);
                this.animateBucket(3, true);
                break;
            }
          }, DELAY.S2);
        }, DELAY.S1);

        if (this.showPage) {
          this._appStateService.appEvent$.next({
            area: APP_EVENT_AREAS.SHRINK_NARRATOR,
          });
        }

        setTimeout(() => {
          this.showBackToDashboardDialog = true;
        }, DELAY.S12);
      }
    });
  }

  burst() {
    this.theBurst.animate(
      {
        containerId: 'burst-1',
        soundEffect: SOUND_NAMES.GameLevelCompleted
      },
      BURST_TYPE.Round,

      true
    );

    this.theBurst.animate(
      {
        containerId: 'burst-2',
      },
      BURST_TYPE.Round,
      true
    );
  }
  ngOnInit(): void {
    this.isTextToSpeechPlaying$ = this._soundService.soundIsPlaying$;
  }

  private animateBucket(id: number, completed: boolean = false) {
    const delay = id * 1000;

    if (completed) {
      setTimeout(() => {
        if (id === 1) {
          this.bucketImage1 = this.bucketImage;
        } else if (id === 2) {
          this.bucketImage2 = this.bucketImage;
        } else {
          this.bucketImage3 = this.bucketImage;
        }

        AnimationHelpers.animate(`bucket-${id}`, ANIMATIONS.Jello);
        this._soundService.playSound(SOUND_NAMES.LightSpell);


      }, delay);
    }
  }

  protected playTextToSpeech(): void {
    this._soundService.playFile(
      `${environment.CDN_URL}/student/audio/5CC7108F0577DCBB6309E4D794E442DD5DB54864A568C0F72E33ED1FE48E66C9.mp3`,
      1,
      false
    );
  }

  private resetState() {
    this.showPage = false;
  }

  protected backToDashboard(): void {
    this._soundService.stopExpression();
    setTimeout(() => {
      this._router.navigate(['/dashboard']);
    }, DELAY.S2);
  }
}
