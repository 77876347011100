import { DELAY } from '@app/shared/constants/delay-enums';

import gsap from 'gsap';
import { MotionPathPlugin } from 'gsap/MotionPathPlugin';
import { AppHelpers } from './app-helpers';
import { IBounceOptions } from '../types/animation-bounce.interface';

export class AnimationHelpers {
  private _idx = 0;
  private _clone?: HTMLElement;

  constructor(
    private readonly _spin: boolean = false,
    private readonly _animations: string[] = [],
    private _straight: boolean = false,
    private _disableX: boolean = false
  ) {
    gsap.registerPlugin(MotionPathPlugin);
  }

  public setStraight(straight: boolean) {
    this._straight = straight;
  }

  public setDisableX(disableX: boolean) {
    this._disableX = disableX;
  }

  public animateTo(srcId: string | undefined, targetId: string | undefined, callback: () => void) {
    if (!srcId || !targetId) {
      if (callback) callback();
      return;
    }

    const targetEl = document.getElementById(targetId);
    const srcEl = document.getElementById(srcId);
    this._idx = 0;

    if (srcEl && targetEl) {
      const rectSrc = srcEl.getBoundingClientRect();
      const rectTarget = targetEl.getBoundingClientRect();

      const scaleX = rectSrc.width / srcEl.offsetWidth;
      const scaleY = rectSrc.height / srcEl.offsetHeight;

      this._clone = srcEl.cloneNode(true) as HTMLElement;

      document.body.appendChild(this._clone);

      this._clone.classList.add('animated');

      if (this._spin) this._clone.classList.add('spin');
      this._clone.style.position = 'absolute';
      this._clone.style.left = `${rectSrc.left}px`;
      this._clone.style.top = `${rectSrc.top}px`;
      this._clone.style.zIndex = '999999';
      this._clone.style.transform = `scale(${scaleX}, ${scaleY})`;
      this._clone.style.transformOrigin = `left top`;

      this._clone.id = AppHelpers.generateId();
      this._animations?.forEach((a) => {
        AnimationHelpers.animate(this._clone?.id, a);
      });

      let targetLeft = rectTarget.left;
      let srcLeft = rectSrc.left;

      if (this._disableX && rectTarget.top != rectSrc.top) {
        targetLeft = 0;
        srcLeft = 0;
      }
      const peak = [(targetLeft - srcLeft) / 2, rectTarget.top - rectSrc.top - 150];
      const curve = [[0, 0], peak, peak, [targetLeft - srcLeft, rectTarget.top - rectSrc.top]];
      const motionPath = {
        path: curve.map((point) => ({ x: point[0], y: point[1] })),
      };

      if (!this._straight) {
        motionPath['type'] = 'cubic';
      }

      gsap.to(this._clone, {
        motionPath,
        ease: 'sine',
        duration: 1.25,
        onComplete: () => {
          if (this._clone) {
            this._clone.remove();
          }
          callback?.();
        },
      });
    }
  }


  public bounceObject(bounceOptions: IBounceOptions) {

    const tl = gsap.timeline();

    const el = `#${bounceOptions.srcId}`;
    const duration = bounceOptions.duration ?? 1;
    const height = bounceOptions.height ?? 200;

    tl.to(el, {
      duration: duration,
      y: -height,
      scale: bounceOptions.expand ? 2 : 1,
      ease: 'power1.out'
    });

    tl.to(el, {
      duration: duration,
      scale: 1,
      ease: 'Bounce.easeOut',
      y: 0,

      onComplete: function () {
        if (bounceOptions.callback) bounceOptions.callback?.();
      },
    });
  }


  // Animation Support for https://animate.style/
  public static animate(id: string | undefined, animation: string, delay = DELAY.S1) {
    if (!id) {
      return;
    }
    const animate = 'animate__animated';
    const className = `animate__${animation}`;
    const srcEl = document.getElementById(id);

    if (srcEl) {
      srcEl.classList.add(animate, className);
      // repeat until class is removed.
      srcEl.style.animationIterationCount = 'infinite';

      setTimeout(() => {
        srcEl.classList.remove(animate, className);
      }, delay);
    }
  }

  public static restoreOpacity(srcId: string) {
    const srcEl = document.getElementById(srcId);
    if (srcEl) {
      srcEl.style.opacity = '1';
    }
  }
}
