<div class="form-container">
  <div class="form-row">
    <form [formGroup]="inputForm" class="login-form" autocomplete="off">
      <div *ngFor="let control of inputArray.controls; let i = index" formArrayName="inputVal">
        <input
          [formControlName]="i"
          class="letter-box letter-box__{{ i }}"
          [type]="i > 1 ? 'number' : 'text'"
          [id]="'loginInput' + i"
          (input)="onInput($event, i)"
          (keyup.enter)="onSubmit(inputArray.value)"
          (click)="selectContents($event)"
          maxlength="1"
          (paste)="onPaste($event, i)"
        />
      </div>
    </form>
    <button class="help-btn" aria-label="Need help?" (click)="showHelpDialog()"  appTrackClick="FHPStuLoginHelp" pTooltip="Need help?">
      <img src="assets/icons/help-icon-small.svg" alt="need help question mark icon" height="25" width="25" aria-hidden="true"/>
    </button>
  </div>

  <div class="form-footer">
    <div *ngIf="inputForm.valid" class="login-button">
      <p-button label="Go" [loading]="loading" (onClick)="onSubmit(inputArray.value)"></p-button>
    </div>
  </div>
</div>
