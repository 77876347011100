import { Injectable } from '@angular/core';
import { API_ENDPOINTS } from '../config/endpoints';
import { HttpClient } from '@angular/common/http';
import { ITrackedEvent } from '../types/tracked-event.interface';
import { AppHelpers } from '../helpers/app-helpers';

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  constructor(private readonly _http: HttpClient) { }

  public track(event: ITrackedEvent) {

    if (AppHelpers.isActivitiesBypass()) {
      return;
    }
    const url = API_ENDPOINTS.trackEvent;
    this._http
      .post<void>(url, event)
      .subscribe();
  }
}
