import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IWordFindFunDataset } from '../../types/word-find-fun-dataset-interface';
import { PUZZLE_STATUS } from '@app/shared/constants/session-enums';
import { IPuzzleCompleteEvent } from '@app/shared/types/puzzle-complete-event-interface';
import { PuzzleTypeBaseComponent } from '@app/pages/activities/puzzle-type-base/puzzle-type-base.component';

@Component({
  selector: 'app-wff-puzzle-type-0',
  templateUrl: './puzzle-type-0.component.html',
  styleUrls: ['./puzzle-type-0.component.scss'],
  imports: [CommonModule],
  standalone: true,
  providers: [],
})
export class WFFPuzzleType0Component extends PuzzleTypeBaseComponent {
  @Input() currentWordFindFunDataset: IWordFindFunDataset | undefined;
  @Input() expressions: Map<number, string>;
  @Output() puzzleComplete = new EventEmitter<IPuzzleCompleteEvent>();
  @Output() displayPB = new EventEmitter();

  protected targetWord: string | undefined;
  protected puzzleOneData: IWordFindFunDataset | undefined;

  constructor() {
    super();
    this.emitCall = this.puzzleComplete;
  }

  initPuzzle() {
    this.setExpressions(this.expressions);
    this.setMaxTries(this.currentWordFindFunDataset?.maxTries)
    this.startPuzzleMetrics();
    this.handPuzzleZeroWord();
    this.displayPB.emit();
  }

  handPuzzleZeroWord(completedWord?: any) {
    this.puzzleOneData = this.currentWordFindFunDataset;
    const arr: string[] | undefined = this.puzzleOneData?.choices.split(',');
    if (completedWord == undefined && arr) {
      this.targetWord = arr?.shift();
    } else if (completedWord && arr) {
      const solvedWord = completedWord.word;
      const index: number | undefined = arr?.indexOf(solvedWord);
      this.targetWord = arr[index + 1];
      if (!this.targetWord) {
        this.handlePuzzleZeroComplete();
      }
    }
  }

  handlePuzzleZeroComplete() {
    this.completePuzzle(PUZZLE_STATUS.PASS);
  }

  // End Puzzle Type 0 ---------------
}
