import { Component, OnInit } from '@angular/core';
import { LoginService } from '@app/pages/login/services/login.service';
import { CurrentUserService } from '@app/shared/services/current-user.service';
import { User } from '@app/shared/types/user-interface';

@Component({
  selector: 'app-primary-layout',
  templateUrl: './primary-layout.component.html',
  styleUrls: ['./primary-layout.component.scss'],
})
export class PrimaryLayoutComponent implements OnInit {
  currentUser: User;

  constructor(private readonly _loginService: LoginService, private readonly _currentUserService: CurrentUserService) {}

  ngOnInit(): void {
    this.currentUser = this._currentUserService.currentUser;
  }

  logout(): void {
    this._loginService.logout().subscribe();
  }
}
