<p-sidebar #sidebarRef [(visible)]="sidebarVisible" position="left" [modal]="false" [baseZIndex]="9999" (onShow)="onSidebarShow()"
  styleClass="p-sidebar-sm" class="sidebar" [showCloseIcon]="false" *ngIf="appEvent$ | async as appEvent">
  <ng-template pTemplate="headless">

    <div class="header">
      Puzzles <span>({{currentDataset.length}})</span>
    </div>
    <div class="complete-all-container">
      <button class="complete-all-button" (click)="completeAllPuzzles()">Complete All Puzzles</button>
    </div>

    <ol>

      <li *ngFor="let row of currentDataset; let i = index">
        <ng-container>
          <div class="template" [ngClass]="{ active: states[i] }" (keydown.enter)="onPuzzleClick($event, i)" (click)="onPuzzleClick($event, i)">
            <!-- Display the JSON without expressions -->
            <ngx-json-viewer [json]="omitExpressions(row)" [expanded]="true"></ngx-json-viewer>
            <!-- Custom rendering for expressions -->
            <div *ngIf="row.expressions" class="expression-container">
              <pre class="json-key">expressions:</pre>
              <ng-container *ngFor="let expression of row.expressions.split('|')">
                <pre class="json-expression">
                  <span class="json-value">{{ expression.trim() }}</span>
                </pre>
              </ng-container>
            </div>
            <div class="checkbox-container">
              <input type="checkbox" id="puzzle-{{i}}" [checked]="row.completed" (change)="onPuzzleToggle($event, i)" />
              <label for="puzzle-{{i}}" class="checkbox-label">
                Mark as Completed
              </label>
            </div>
          </div>
        </ng-container>
      </li>

    </ol>

  </ng-template>
</p-sidebar>
