import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_ENDPOINTS } from '@app/shared/config/endpoints';
import { IDashboardResponse } from '../types/assignment.types';

@Injectable()
export class AssignmentService {
  constructor(private readonly _http: HttpClient) {}

  getStudentAssignments(studentId: string, teacherPasscode: string | null): Observable<IDashboardResponse[]> {
    return this._http.get<IDashboardResponse[]>(`${API_ENDPOINTS.getStudentAssignments}/${studentId}/${teacherPasscode}`);
  }
}
