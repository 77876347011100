import { Component } from '@angular/core';
import { AppStateService } from '@app/shared/services/app-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(private readonly _appStateService: AppStateService) {
    // store params from url in app state
    this._appStateService.persistedUrlParams = this.extractParamsFromUrlString(window.location.search);
  }

  extractParamsFromUrlString(url: string) {
    // Remove the '?' from the beginning of the URL string, if present
    const paramString = url.startsWith('?') ? url.slice(1) : url;

    // Split the parameters string by '&' to get an array of key-value pairs
    const paramPairs = paramString.split('&');

    // Initialize an object to store the parameters
    const paramsObject = {};

    // Loop through the key-value pairs and populate the object
    paramPairs.forEach((paramPair) => {
      const [key, value] = paramPair.split('=');
      paramsObject[key] = value;
    });
    return paramsObject;
  }
}
