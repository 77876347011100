<ng-container>
  <div class="wow-overlay" *ngIf="showOverlay"></div>

  <div class="review-paragraph-host" *ngIf="showOverlay">
    <div class="final-paragraph-review">
      <button
        id="close-story"
        class="image-button round"
        (click)="onReviewParagraph()"
        aria-label="Close full story"
        pTooltip="Close full story"
      >
        <i class="pi pi-times" aria-hidden="true"></i>
      </button>
      <div class="label">Passage:</div>
      {{ this.finalParagraphReview }}
    </div>
    <button
      id="lightbulb"
      class="image-button round"
      aria-label="Review full paragraph"
      pTooltip="Review full paragraph"
      (click)="onReviewParagraph()"
    ></button>
  </div>

  <div id="build-paragraph" class="build-paragraph">
    <div [id]="wordDiv" class="final-paragraph">
      {{ this.finalParagraph }}
    </div>
    <ol id="sentence-choices" class="sentence-choices flex justify-content-center flex-wrap" *ngIf="!isPuzzleSolved">
      <button
        id="lightbulb"
        class="image-button round"
        aria-label="Show full story"
        pTooltip="Show full story"
        (click)="onReviewParagraph()"
      ></button>

      <li [id]="choice.id + '-1'" *ngFor="let choice of sentenceChoices" class="sentence-host" [ngClass]="{ solved: choice.solved }">
        <button
          [id]="choice.id + '-sound'"
          class="sound-icon image-button round"
          aria-label="Listen to sentence"
          pTooltip="Listen to sentence"
          (click)="onPlaySentence(choice)"
        ></button>

        <div
          [id]="choice.id"
          class="sentence-choice animateSpanSentence flex m-2"
          (click)="onSentenceChoiceClicked(choice)"
          (keyup.enter)="onSentenceChoiceClicked(choice)"
          (keyup.space)="onSentenceChoiceClicked(choice)"
          (keyup)="this.onArrowKey($event)"
          [ngClass]="{ active: choice.active, correct: choice.solved, error: choice.error, solved: choice.solved }"
        >
          {{ choice.display }}
        </div>
        <div class="answer-status" [ngClass]="{ statuscorrect: choice.correct, statuserror: choice.error }"></div>
      </li>
    </ol>
  </div>
</ng-container>
