import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_ENDPOINTS } from '@app/shared/config/endpoints';
import {
  IStudentIconPasscodeValidationResponse,
  IStudentInitialsValidationResponse,
  IStudentLoginRequest,
  ITeacherPasscodeValidationResponse,
} from '@app/pages/login/types/login.types';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private readonly _authCheckInterval = 300000; // 5 mins
  authCheckTimer: number;

  constructor(private readonly _http: HttpClient) {
    this.setAuthCheckTimer();
  }

  validateTeacherPasscode(passcode: string): Observable<ITeacherPasscodeValidationResponse | null> {
    return this._http.get<ITeacherPasscodeValidationResponse>(`${API_ENDPOINTS.validateTeacherPasscode}/${passcode}`);
  }

  validateStudentInitials(initials: string, passcode: string): Observable<IStudentInitialsValidationResponse | null> {
    return this._http.get<IStudentInitialsValidationResponse>(`${API_ENDPOINTS.validateStudentInitials}/${initials}/${passcode}`);
  }

  validateStudentIconPasscode(request: IStudentLoginRequest): Observable<IStudentIconPasscodeValidationResponse | null> {
    return this._http.post<IStudentIconPasscodeValidationResponse>(`${API_ENDPOINTS.validateStudentIconPasscode}`, request);
  }

  loginClever(returnUri: string): Observable<void> {
    return this._http.get<void>(`${API_ENDPOINTS.loginClever}`).pipe(
      tap((result: any) => {
        window.location.href = `${atob(result.uri)}/${btoa(returnUri)}`;
      })
    );
  }

  logoutClever(returnUri: string): Observable<void> {
    return this._http
      .get<void>(`${API_ENDPOINTS.logoutClever}`)
      .pipe(tap((result: any) => (document.location.href = `${atob(result.uri)}/${btoa(returnUri)}`)));
  }

  setXsrfAsync(): Observable<void> {
    return this._http.get<void>(API_ENDPOINTS.setXsrf);
  }

  setAuthCheckTimer(): void {
    this.clearAuthCheckTimer();

    this.authCheckTimer = window.setInterval(() => {
      this.isUserLoggedIn().subscribe();
    }, this._authCheckInterval);
  }

  isUserLoggedIn(): Observable<boolean | void> {
    return this._http.get<boolean>(API_ENDPOINTS.isAuthenticated);
  }

  private clearAuthCheckTimer(): void {
    window.clearInterval(this.authCheckTimer);
  }

  logout(): Observable<void> {
    return this._http.post<void>(API_ENDPOINTS.logout, null).pipe(
      tap(() => {
        window.location.href = '/login';
      })
    );
  }
}
