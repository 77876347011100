<div class="activity-header col-12 flex justify-content-between align-self-end">
  <div class="title flex flex-row sm:flex-wrap align-items-baseline">
    <div class="company" *ngIf="params">
      <img [ngSrc]="titleImage" pTooltip="Fundations" width="214" height="23" alt="Fundations" priority />
    </div>
    <div class="activity-info" *ngIf="params" [ngClass]="{ white: isDarkBackground }">
      <span>Level {{ params["l"] }}</span> - <span>{{ activity.name }}</span> - <span> {{ unitDisplay }}</span> -
      <span>Week {{ params["w"] }}</span>
    </div>
  </div>
  <div *ngIf="activityStarted && !activityCompletionPageShown" class="ctrl-buttons flex flex-row">
    <button
      class="image-button round mx-2"
      (click)="onKeyboardButtonClick()"
      (keydown.enter)="onKeyboardButtonClick()"
      pTooltip="Keyboard Instructions"
      [attr.data-automationId]="'keyboard'"
      appTrackClick="FHPStuKeyboard"
    >
      <img [ngSrc]="keyboardButtonImage" width="40" height="40" alt="keyboard instructions icon" aria-hidden="true" />
    </button>

    <button
      #fullScreenBtn
      class="image-button round mx-2"
      (click)="isFullScreen ? exitFullScreenMode() : enterFullScreenMode()"
      (keydown.enter)="isFullScreen ? exitFullScreenMode() : enterFullScreenMode()"
      [pTooltip]="isFullScreen ? 'Exit Full Screen' : 'Full Screen'"
      [attr.data-automationId]="'exit-activity'"
    >
      <img
        [ngSrc]="isFullScreen ? exitFullScreenButtonImage : fullScreenButtonImage"
        width="40"
        height="40"
        alt="toggle full screen icon"
        aria-hidden="true"
      />
    </button>

    <button
      class="image-button round mx-2"
      (click)="onCloseClick()"
      (keydown.enter)="onCloseClick()"
      pTooltip="Exit Activity"
      [attr.data-automationId]="'exit-activity'"
    >
      <img [ngSrc]="exitButtonImage" width="40" height="40" alt="close icon" aria-hidden="true" />
    </button>
  </div>
</div>

<p-confirmDialog [focusTrap]="true" [defaultFocus]="'accept'" [style]="{ width: '75vw' }" [closable]="false"></p-confirmDialog>

<p-dialog
  aria-hidden="true"
  cdkTrapFocus="true"
  [style]="{ width: '50rem' }"
  [modal]="true"
  [(visible)]="keyboardVisibility"
  [focusOnShow]="true"
  [draggable]="false"
  (onHide)="closeKeyboardInstructions()"
>
  <div class="keyboard-dialog">
    <div class="keyboard-dialog__header">
      <span class="font-bold">Enter key / Spacebar</span>
      <span>to select answer choice</span>
    </div>
    <div class="keyboard-dialog__image">
      <img ngSrc="/assets/keyboard.svg" width="700" height="241" alt="outline of english keyboard" />
    </div>
    <div class="keyboard-dialog__footer">
      <button
        class="image-button round"
        (click)="onPlayKeyboardInstructions()"
        aria-label="Read keyboard instructions"
        pTooltip="Read keyboard instructions"
        [disabled]="isTextToSpeechPlaying$ | async"
      >
        <img [ngSrc]="soundButtonImage" width="36" height="36" alt="speaker icon" aria-hidden="true" />
      </button>
      <div class="footer-text"><strong>Arrow keys</strong> to move</div>
    </div>
  </div>
</p-dialog>

<p-dialog
  [closable]="false"
  cdkAriaLive="assertive"
  [style]="{ width: '40vw' }"
  [modal]="true"
  [(visible)]="showExitDialog"
  [focusOnShow]="true"
  cdkTrapFocus="true"
>
  <div class="text-center">
    <img class="header-icon" src="/assets/warning-icon.svg" alt="warning icon" />
    <h2>Exit Activity</h2>
    <p>Are you sure you want to exit?</p>
    <div class="cta-sound-combo mt-4">
      <button class="btn-primary m-1" (click)="closeExitDialog(true)" (keydown.enter)="closeExitDialog(true)">Yes</button>
      <button class="btn-primary m-1" (click)="closeExitDialog(false)" (keydown.enter)="closeExitDialog(false)">No</button>
      <button class="t2s-btn bottom-right-sm" (click)="playTextToSpeech()" [disabled]="isTextToSpeechPlaying$ | async">
        <img *ngIf="isTextToSpeechPlaying$ | async" src="/assets/text-to-speech-inactive.svg" alt="text-to-speech disabled button" />
        <img
          *ngIf="(isTextToSpeechPlaying$ | async) === false"
          src="/assets/text-to-speech-active.svg"
          alt="text-to-speech button"
          pTooltip="Read popup content"
        />
      </button>
    </div>
  </div>
</p-dialog>
