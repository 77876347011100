import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest, HttpXsrfTokenExtractor } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable()
export class AuthorizationInterceptor implements HttpInterceptor {
  constructor(private readonly tokenExtractor: HttpXsrfTokenExtractor) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    // Only attach headers + send cookies on requests to our own server
    if (!request.url.startsWith(environment.API_URL)) return next.handle(request);

    let headers = request.headers.set('X-REQUESTED-WITH', 'XMLHttpRequest:Angular');
    const csrfToken: string = this.tokenExtractor.getToken() as string;

    if (csrfToken != null && !request.headers.has('X-XSRF-TOKEN')) {
      headers = headers.set('X-XSRF-TOKEN', csrfToken);
    }

    const clone = request.clone({
      headers: headers,
      withCredentials: true,
    });

    return next.handle(clone);
  }
}
