import { Injectable } from '@angular/core';
import { AppStateService } from '@app/shared/services/app-state.service';
import { CurrentUserService } from '@app/shared/services/current-user.service';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  constructor(private readonly _appStateService: AppStateService, private readonly _currentUserService: CurrentUserService) {}

  isLoggedIn: boolean;
  isValidPreview: boolean;

  async canActivate(): Promise<boolean> {
    //check for bypass before checking login status
    if (await this.isBypassed()) return true;
    if (this._currentUserService.isCurrentUserLoggedIn()) return true;
    return !!(await lastValueFrom(this._currentUserService.getUser()));
  }

  private async isBypassed(): Promise<boolean> {
    const token = this._appStateService.persistedUrlParams['token'];
    if (token) return !!(await lastValueFrom(this._currentUserService.validatePreviewUser(token)));
    return false;
  }
}
