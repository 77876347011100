<div class="load-carousel">
  <div class="rotator">
    <div class="carousel" #carousel [ngClass]="'l' + currentLevel">
      <div
        class="carousel-cell shadow-4"
        #cell
        *ngFor="let card of this.cards; let idx = index"
        [ngClass]="{
        active: card.active,
        }"
      >
        <div class="content">
          {{ card.content }}
        </div>
      </div>
    </div>

    <!-- Conditionally show the microphone or arrow based on recording status -->
    <button
      *ngIf="!isRecordingEnabled && totalCardsShown <= cards.length"
      class="arrow right button image-button"
      (click)="rotate(1)"
      aria-label="Next"
      pTooltip="Next"
      tooltipPosition="left"
    >
      <img [ngSrc]="rightButtonImage" height="71" width="106" alt="right arrow" aria-hidden="true" />
    </button>

    <div class="mic-sound-container" *ngIf="isRecording && totalCardsShown <= cards.length">
      <button
        *ngIf="!isRecordingActive"
        class="mic image-button"
        (click)="doRecord()"
        aria-label="Record"
        pTooltip="Record"
        tooltipPosition="left"
        appTrackClick="FHPStuCaroseulRecord"
      >
        <img [src]="micButtonImage" height="71" width="106" alt="microphone icon" aria-hidden="true" />
      </button>

      <button
        *ngIf="isRecordingActive"
        class="stop image-button"
        (click)="stopRecording()"
        [disabled]="!recorderInstance"  
        aria-label="Stop"
        pTooltip="Stop"
        tooltipPosition="left"
        appTrackClick="FHPStuCaroseulStop"
      >
        <img [src]="stopButtonImage" height="71" width="106" alt="stop icon" aria-hidden="true" />
      </button>
      
      <!-- Sound bar image next to mic -->
      <ng-container class="sound-bar">
        <app-audio-visualizer></app-audio-visualizer>
      </ng-container>
    </div>
  </div>
</div>
