import { Message, MessageService } from 'primeng/api';
import { inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  private readonly toaster = inject(MessageService);
  /**
   *
   * @param type - http or runtime error
   * @param message - content of error message
   * @param severity - determines toast theme - success, info, warn, error
   */
  setErrorToast(type: string, message: string, severity?: string) {
    const msg: Message = {
      summary: type + 'Error',
      detail: message,
      severity: severity ?? 'error', // default to error, but we can change or make severity required
      life: 5000,
    };
    this.toaster.add(msg);
  }
}
