import { Component, inject, OnInit, ViewChild, ElementRef, DestroyRef } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { DialogModule } from 'primeng/dialog';
import { SoundService } from '@app/shared/services/sound.service';
import { Observable } from 'rxjs/internal/Observable';
import { IAppEvent } from '@app/shared/types/app-event-interface';
import { AppStateService } from '@app/shared/services/app-state.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { TooltipModule } from 'primeng/tooltip';
import { A11yModule } from '@angular/cdk/a11y';
import { ActivitySessionService } from '@app/shared/services/activity-session.service';

@Component({
  selector: 'app-help-panel',
  standalone: true,
  templateUrl: './help-panel.component.html',
  styleUrls: ['./help-panel.component.scss'],
  imports: [CommonModule, NgOptimizedImage, DialogModule, TooltipModule, A11yModule],
})
export class HelpPanelComponent implements OnInit {
  @ViewChild('audioButton') audioButton: ElementRef;

  protected visibility = false;
  protected puzzleClueExpression = '';
  protected appEvent$: Observable<IAppEvent>;
  protected questionButtonImage = '';
  protected howToPlayButtonImage = '';
  protected showHowToPlay = false;
  protected showRepeat = true;
  protected callback;

  private readonly _appStateService = inject(AppStateService);
  private readonly _soundService = inject(SoundService);
  private readonly _destroyRef = inject(DestroyRef);
  private readonly _activityService = inject(ActivitySessionService);

  ngOnInit() {
    this.appEvent$ = this._appStateService.appEvent$;

    this.appEvent$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event.area === APP_EVENT_AREAS.PUZZLE_CLUE) {
        this.puzzleClueExpression = event.puzzleClueExpression ?? '';
        this.callback = event.callback || undefined;
      }
    });

    this._activityService._showAudioRepeatButton$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((show) => {
      this.showRepeat = show;
    });

    // based upon level, we show different color buttons
    this.questionButtonImage = `/assets/repeat-button-${this._appStateService.level}.svg`;
    this.howToPlayButtonImage = `/assets/question-button-${this._appStateService.level}.svg`;
  }

  onAudioButtonClick() {
    this._soundService.playExpression(this.puzzleClueExpression, () =>{
      if(this.callback) this.callback();
    });
  }

  onHowToVideoButtonClick() {
    this._appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.HOW_TO_PLAY,
    });
  }

  // @HostListener('window:keydown', ['$event'])
  // handleKeyDown(event: KeyboardEvent) {
  //   // how to video
  //   if (event.key === 'q') {
  //     if (!this.showHowToPlay) return;
  //     this.onHowToVideoButtonClick();
  //   }
  //   // repeat instructions
  //   if (event.key === 'r') {
  //     if (this.puzzleClueExpression.length === 0) return;
  //     this.onAudioButtonClick();
  //   }
  // }
}
