import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable } from 'rxjs';
import { IAppSettings } from '@app/shared/types/app-settings-interface';
import { environment } from '@env/environment';
import { AppStateService } from '@app/shared/services/app-state.service';

@Injectable({
  providedIn: 'root',
})
export class AppSettingsService {
  constructor(private readonly _http: HttpClient, private readonly _appState: AppStateService) {}

  getAppSettings(): Observable<IAppSettings> {
    const path = environment.CONFIG_DIR || `${environment.CDN_URL}/activities/app-settings`;
    const appSettingsURL = `${path}/app-settings.json`;
    return this._http.get<IAppSettings[]>(appSettingsURL).pipe(
      map((res: IAppSettings[]) => {
        return res.filter((item) => item.level === +this._appState.level)[0];
      })
    );
  }
}
