import { Component, EventEmitter, Output ,inject} from '@angular/core';
import { CommonModule} from '@angular/common';
import { environment } from '@env/environment';
import { SoundService } from '@app/shared/services/sound.service';
import { TrackingModule } from '@app/shared/directives/tracking.module';


@Component({
  selector: 'app-mic-access-popup',
  standalone: true,
  imports: [CommonModule, TrackingModule],
  templateUrl: './mic-access-popup.component.html',
  styleUrls: ['./mic-access-popup.component.scss'],
})
export class MicAccessPopupComponent{
    @Output() allowAccess = new EventEmitter<boolean>(); // Emits whether the user allowed access or not
    protected exitButtonImage = `/assets/exit-button-black.svg`;
    protected textToSpeechButton = '/assets/text-to-speech-active.svg';
    private readonly _soundService: SoundService = inject(SoundService);

    constructor() {}

    onAllow() {
      this.allowAccess.emit(true);  // Emit true when the "Allow" button is clicked
    }

    playMicAccessPopupAudio(){
      //need updated audio file for new pop-up text
      this._soundService.playFile(
        `${environment.CDN_URL}/student/audio/E14335754F3582AE5B595F7914AE0AE11A0625247FA76A53CCC65935E41F5AF6.mp3`,
        1,
        false
      );
    }
}
