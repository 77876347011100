import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { User } from '../types/user-interface';
import { API_ENDPOINTS } from '../config/endpoints';
import { Observable, catchError, tap } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  private readonly _http = inject(HttpClient);
  private readonly _router = inject(Router);

  public currentUser: User;

  getUser(): Observable<boolean | User> {
    return this._http.get<User>(API_ENDPOINTS.currentUser).pipe(
      tap((res) => {
        this.currentUser = res;
      }),
      catchError(() => {
        return this._router.navigate(['login']);
      })
    );
  }

  validatePreviewUser(token: string): Observable<boolean> {
    return this._http.get<boolean>(`${API_ENDPOINTS.validatePreviewToken}/${token}`);
  }

  isCurrentUserLoggedIn(): boolean {
    return !!this.currentUser;
  }
}
