<div class="mic-popup">
  <div class="popup-content">
    <h3>Select "Allow" on the next pop-up to let FUN HUB Practice use your microphone.</h3>
    <div class="popup-actions">
      <button (click)="onAllow()" appTrackClick="FHPStuRecordPermissionsYes">OK</button>
    </div>
    <img 
      src="{{ textToSpeechButton }}" 
      class="text-to-speech-button" 
      (keydown.enter)="playMicAccessPopupAudio()"
      (click)="playMicAccessPopupAudio()" 
      alt="text to speech icon" 
      aria-hidden="true">
  </div>
</div>

