import { CommonModule, Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { SoundService } from '@app/shared/services/sound.service';
import { environment } from '@env/environment';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  imports: [CommonModule],
  standalone: true,
  styleUrls: ['./not-found.component.scss'],
})
export class NotFoundComponent implements OnInit {
  isTextToSpeechPlaying$: BehaviorSubject<boolean>;

  constructor(private readonly _soundService: SoundService, private readonly location: Location, private readonly router: Router) {}

  ngOnInit(): void {
    this.isTextToSpeechPlaying$ = this._soundService.soundIsPlaying$;
  }

  protected playTextToSpeech(): void {
    this._soundService.playFile(
      `${environment.CDN_URL}/student/audio/97D6D9A1D825C84D5C0CFCA368364E2170051D014F9ECA2F924BC05EE1F257DA.mp3`,
      1,
      false
    );
  }

  protected goBack(): void {
    if (window.history.length > 1) {
      this.location.back();
    } else {
      this.router.navigate(['/dashboard']);
    }
  }
}
