import { A11yModule } from '@angular/cdk/a11y';
import { CommonModule} from '@angular/common';
import { Component, DestroyRef, Input, ViewChild, inject } from '@angular/core';
import { IEchoFindWordsDataset } from './types/echo-find-words-dataset-interface';
import { SoundService } from '@app/shared/services/sound.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivityBaseComponent } from '../activity-base/activity-base.component';
import { DELAY } from '@app/shared/constants/delay-enums';
import { BWEPuzzleType0Component } from './puzzle-types/0/puzzle-type-0.component';
import { BWEPuzzleType1Component } from './puzzle-types/1/puzzle-type-1.component';
import { BWEPuzzleType2Component } from './puzzle-types/2/puzzle-type-2.component';
import { BWEPuzzleType3Component } from './puzzle-types/3/puzzle-type-3.component';
import { APP_EVENT_AREAS } from '@app/shared/constants/app-event-areas';
import { ProgressBarComponent } from "../../../shared/components/progress-bar/progress-bar.component";
import { COLON_SEPARATOR, SYLLABLE_SEPARATOR } from '@app/shared/constants/activity-constants';


enum PUZZLE_TYPE {
  Basic = 0,
  SpellingOption,
  Doubling,
  Suffix,
  Unknown,
}

@Component({
  selector: 'app-echo-find-words-activity',
  templateUrl: './echo-find-words-activity.component.html',
  styleUrls: ['./echo-find-words-activity.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    A11yModule,
    BWEPuzzleType0Component,
    BWEPuzzleType1Component,
    BWEPuzzleType2Component,
    BWEPuzzleType3Component,
    ProgressBarComponent
],
})
export class EchoFindWordsActivityComponent extends ActivityBaseComponent {
  @Input() subPartComplete: boolean;

  protected buildEchoDatasets: IEchoFindWordsDataset[];
  protected currentBuildEchoDataset: IEchoFindWordsDataset | undefined;
  protected currentGroup: number;
  protected positionCommands:  Map<number, string> = new Map();


  protected totalPuzzles: number = 0;
  protected answeredPuzzles: number = 0;
  protected puzzlesCompleted: number;
  protected displayPB: boolean = false;

  protected puzzleTypeEnum = PUZZLE_TYPE;
  protected puzzleType: PUZZLE_TYPE;

  // PT 0
  @ViewChild(BWEPuzzleType0Component)
  private readonly _puzzleType0: BWEPuzzleType0Component;
  // PT 1
  @ViewChild(BWEPuzzleType1Component)
  private readonly _puzzleType1: BWEPuzzleType1Component;
  // PT 2
  @ViewChild(BWEPuzzleType2Component)
  private readonly _puzzleType2: BWEPuzzleType2Component;
  // PT 3
  @ViewChild(BWEPuzzleType3Component)
  private readonly _puzzleType3: BWEPuzzleType3Component;

  private readonly  _destroyRef = inject(DestroyRef);
  private readonly _soundService = inject(SoundService);

  @ViewChild(ProgressBarComponent)
  private readonly _progressBarComp!: ProgressBarComponent;

  constructor() {
    super();
  }

  // processKeyEvent(event: KeyboardEvent) { }

  initActivity(callback?: () => void | undefined): void {
    this.loadDataset()
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe((datasetSrc) => {
        this.buildEchoDatasets = datasetSrc as IEchoFindWordsDataset[];
        this.puzzlesCompleted = this.buildEchoDatasets.filter((d) => d.completed).length;
        this.buildEchoDatasets.forEach((lp,i) => {
          const word = lp.word.split("-")
          lp.group = i;
          this.totalPuzzles += word.length;
        })
        this.currentBuildEchoDataset = this.buildEchoDatasets.filter((d) => !d.completed)[0];
        this.markerCount = this.buildEchoDatasets.length;
        this.currentGroup =  this.currentBuildEchoDataset.group;
        this.subpartComplete()
        if (callback) callback();
        this.listenForJumpToPuzzle();
        this.listenForPuzzleCompletion(); 
      });

      this.listenForCompleteAllPuzzles();
  }

  subpartComplete(){
    this.buildEchoDatasets.forEach((d) =>{
      if(d.group < this.currentGroup){
        d.completed = true;
        this.answeredPuzzles+=d.word.split("-").length;
      }
    })
  }

  initPuzzle() {
    if (!this.currentBuildEchoDataset) {
      this.showActivitySolved();
      return;
    }

    this.puzzleType = this.currentBuildEchoDataset.puzzleType || 0;

    this.buildExpressions(this.currentBuildEchoDataset);
    this.buildPositionCommands(this.currentBuildEchoDataset.positionCommands)

    this.appStateService.appEvent$.next({
      area: APP_EVENT_AREAS.PROFILE_NARRATOR,
    });
    this.displayPB = true;

    setTimeout(() => {
      switch (this.puzzleType) {
        case PUZZLE_TYPE.Basic:
          this._puzzleType0.initPuzzle();
          break;
        case PUZZLE_TYPE.SpellingOption:
          this._puzzleType1.initPuzzle();
          break;
        case PUZZLE_TYPE.Doubling:
          this._puzzleType2.initPuzzle();
          break;
        case PUZZLE_TYPE.Suffix:
          this._puzzleType3.initPuzzle();
          break;
        default:
          break;
      }
    }, DELAY.ZERO);
  }

  getNextDataset() {
    this.updateSession();

    if (this.currentBuildEchoDataset) {
      this.currentBuildEchoDataset.completed = true;
        this.currentBuildEchoDataset = this.buildEchoDatasets.find((be) => !be.completed);
        this.initPuzzle();
    }
  }

  showActivitySolved() {
    this._progressBarComp.progressBarComplete(() => {
      this.activitySolved = true;
      this.puzzleType = PUZZLE_TYPE.Unknown;
      this._progressBarComp.reset();

      this.playEndExpression();
      this.toggleActivityCompletePage(true);
    });
  }

  startActivity() {
    if (this.activitySolved) {
      this.activitySolved = false;
      this.toggleActivityCompletePage(false);
    }

    this.initActivity(() => {
      this.setPerfectScore(this.buildEchoDatasets.length || 0);
      this.soundService.playExpression(this.currentActivity.startExpression, () => {
        this.initPuzzle();
        this._progressBarComp.markedComplete(this.answeredPuzzles);
      });
    });
  }

  onPuzzleComplete() {
    this.getNextDataset();
  }

  incrementProgress(data){
    this._progressBarComp.proceed(()=>{},data.passed)
  }

  private listenForJumpToPuzzle() {
    this.appStateService.appEvent$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event.area === APP_EVENT_AREAS.JUMP_TO_PUZZLE) {
        if (event.payload < this.buildEchoDatasets.length) {
          this.currentBuildEchoDataset = this.buildEchoDatasets[event.payload];
          this.initPuzzle();
        }
      }
    });
  }

  private listenForPuzzleCompletion() {
    this.appStateService.activityDataset$
      .pipe(takeUntilDestroyed(this._destroyRef))
      .subscribe(dataset => {
        if (dataset) {
          this.buildEchoDatasets = dataset as IEchoFindWordsDataset[];
        }
      });
  }

  private listenForCompleteAllPuzzles(): void {
    this.appStateService.appEvent$.pipe(takeUntilDestroyed(this._destroyRef)).subscribe((event) => {
      if (event.area === APP_EVENT_AREAS.COMPLETE_ALL_PUZZLES) {
        this.completeAllPuzzles();
        this.updateActivityAfterCompletion();
      }
    });
  }
  
  private updateActivityAfterCompletion(): void {
    // Check if all puzzles are completed and update the UI accordingly
    this.currentBuildEchoDataset = this.buildEchoDatasets?.find((d) => !d.completed);
    if (!this.currentBuildEchoDataset) {
      this.showActivitySolved(); // Show activity completed if all puzzles are done
    } else {
      this.initPuzzle(); // Reinitialize the current puzzle if there are any left
    }
  }

    buildPositionCommands(dataset: any) {
    if (!dataset) {
      return;
    }
    const result = {};
    const parsed = JSON.parse(dataset);
    for (const key in parsed) {
      const lowercase = key.toLowerCase();
      result[lowercase] = parsed[key];
    }
    dataset = result;

    const expressions = dataset.expressions.split(SYLLABLE_SEPARATOR);

    expressions.forEach((e, idx) => {
      const exp = e.split(COLON_SEPARATOR);
      const key = idx + 1;
      let value: string;
      if (exp.length === 1) {
        value = exp[0].trim();
      } else {
        value = exp.length > 1 ? exp[1].trim() : '';
      }
      this.positionCommands.set(key, value.trim());
    });
    
    return this.positionCommands;
  }
}
