import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ActivityRegistryService {
  private _level = 0;
  private _unit = 0;
  private _week = 0;
  private _activitySessionId = '';

  constructor(private readonly _http: HttpClient) {}

  setActivityParams(level: number, unit: number, week: number, _activitySessionId: string) {
    this._level = level;
    this._unit = unit;
    this._week = week;
    this._activitySessionId = _activitySessionId;
  }

  get level() {
    return this._level;
  }

  get unit() {
    return this._unit;
  }

  get week() {
    return this._week;
  }

  get activitySessionId() {
    return this._activitySessionId;
  }
}
