<!-- PuzzleType 0 - Syllables -->
<ng-container>
  <div class="grid">
    <div class="col-3">
      <div class="prefix text-center" [id]="this.activePrefix?.id">
        {{ this.activePrefix?.prefix?.display }}
      </div>
    </div>
    <div class="col-3">
      <ol class="list-box syllable-choices">
        <li
          class="syllable-choice-host"
          *ngFor="let choice of this.activePrefix?.choices; let idx = index"
          (click)="onSyllableChoiceSelected(choice)"
          (keyup)="this.onArrowKey($event)"
          (keyup.enter)="onSyllableChoiceSelected(choice)"
          (keyup.space)="onSyllableChoiceSelected(choice)"
        >
          <div
            class="syllable-choice animateSpan"
            [id]="choice.id"
            [ngClass]="{ active: choice.active, correct: choice.solved, error: choice.error, valid: choice.valid }"
          >
            {{ choice.display }}
          </div>
          <div class="answer-status" [ngClass]="{ statuscorrect: choice.solved, statuserror: choice.error }"></div>
        </li>
      </ol>
    </div>
    <div class="col-6">
      <ol class="list-box solved-words" [id]="wordDiv">
        <li *ngFor="let word of this.solvedWords; let idx = index">
          <div class="word-div" [id]="word.id">
            {{ word.display }}
          </div>
        </li>
      </ol>
    </div>
  </div>
</ng-container>
