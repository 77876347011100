import { Component, OnInit } from '@angular/core';
import { LoginService } from './services/login.service';
import { tap } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login-success',
  template: '',
})
export class LoginSuccessComponent implements OnInit {
  constructor(private readonly loginService: LoginService, private readonly router: Router) {}

  ngOnInit(): void {
    this.loginService
      .setXsrfAsync()
      .pipe(tap(() => this.router.navigate(['/dashboard'])))
      .subscribe();
  }
}
