import { Directive, HostListener, Input } from '@angular/core';
import { TrackingService } from '../services/tracking.service';

@Directive({
  selector: '[appTrackClick]',
})
export class appTrackClickDirective {
  constructor(private readonly _trackingService: TrackingService) {}
  @Input('appTrackClick') eventName: string;

  @HostListener('click', ['$event'])
  handleClick(event: Event) {
    event.stopPropagation();
    
    if (this.eventName) this._trackingService.track({
      name: this.eventName,
      props: null
    });
  }
}
