import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { appTrackClickDirective } from './track-click.directive';

@NgModule({
  declarations: [appTrackClickDirective],
  imports: [CommonModule],
  exports: [appTrackClickDirective],
})
export class TrackingModule {}
