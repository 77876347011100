<div class="dashboard-page container">
  <div class="title-bar flex justify-content-between">
    <h1>My Assignments</h1>
  </div>
  <div *ngIf="showLoading" class="progress-container">
    <p-progressSpinner strokeWidth="3" ariaLabel="loading"></p-progressSpinner>
  </div>
  <ng-container *ngIf="!showLoading">
    <ng-container *ngIf="activityTiles.length; else noActivities">
      <div class="grid">
        <div *ngFor="let tile of activityTiles" class="xxl:col-2 lg:col-3 md:col-4 sm:col-6 col-12">
          <div class="activity-tile hoverable" (keydown.enter)="openActivity(tile)" (click)="openActivity(tile)">
            <div class="activity-tile__header">
              <img loading="lazy" [src]="tile.image" alt="{{ tile.title }} thumbnail"/>
            </div>
            <a class="activity-tile__play-button">
              <span>{{ tile.hasStarted ? "Resume" : "Go" }}</span>
              <img class="play-icon" src="/assets/play-icon.svg" alt="play icon" />
            </a>
            <div class="activity-tile__body">
              <h4 class="activity-tile__title ellipsis" title="{{ tile.title }}">{{ tile.title }}</h4>
              <p class="activity-tile__description">{{ tile.unit }} - Week {{ tile.week }}</p>
              <!-- WILSONTODO: Hiding this for now, since it won't be available for BTS '24. -->
              <!-- <button class="activity-tile__help-button" (click)="toggleTutorialVideoModal($event, tile.tutorialVideo)">
                {{ instructionsLinkText }}
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #noActivities>
      <div>
        <p>You don't have any incomplete activities assigned to you. Find a teacher or adult who can help you.</p>
      </div>
    </ng-template>
  </ng-container>

  <p-dialog
    [closable]="false"
    cdkAriaLive="assertive"
    [style]="{ width: '75vw' }"
    header="How to Play"
    [closable]="true"
    [modal]="true"
    [(visible)]="tutorialModalVisible"
    [focusOnShow]="true"
    cdkTrapFocus="true"
    [dismissableMask]="true"
  >
    <div class="iframe-container">
      <iframe [src]="activeTutorialUrl | sanitizeUrl" class="full-iframe" title="How to play tutorial"></iframe>
    </div>
  </p-dialog>
</div>
